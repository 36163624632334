import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import {ConfigurationSchedule} from 'projects/qcloud-models/grafik/configuration/configurationSchedule.model';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationScheduleService
{
    appBase: string;
    ConfigurationSchedule : ConfigurationSchedule;

    constructor(private http: HttpClient, private authService: AuthService) { }

    public setAppBase(appBase: string){
        this.appBase = appBase;
    }

    getConfiguration() : Observable<ConfigurationSchedule>{
        return this.http.get<ConfigurationSchedule>(
            `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/configWorkerSchedule/`);
    }
    
    editConfiguration(configurationSchedule : ConfigurationSchedule) : Observable<ConfigurationSchedule> {
        return this.http.put<ConfigurationSchedule>(
            `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/configWorkerSchedule/`,
            configurationSchedule);
      }
}