import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { ConsoleLogin } from 'projects/qcloud-models/user/console-login.model';
import { AuthService } from './auth/auth.service';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})
export class ConsoleRestService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  public loginToConsole(consoleLogin: ConsoleLogin, callback: (data: any) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer `);
    this.http.post(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/console/token`,
    consoleLogin, { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  public logoutFromConsole(userId: string, deskId: number, callback: (data: any) => any, onError: (error) => any) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', `Bearer `);
    this.http.get(`${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/console/logout/${userId}/${deskId}`,
    { headers: headers })
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
}
