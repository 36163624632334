import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { FileService } from 'projects/qcloud-rest-client/src/lib/file.service';
import { SystemService } from 'projects/qcloud-rest-client/src/lib/system.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  username: string;
  userId: string;
  serveETickets: boolean;
  isSidebarToggled: boolean;
  isSidebarVisible: boolean;

  constructor(private router: Router, public authService: AuthService,
    private translate: TranslateService, public locationService: LocationService,
    public fileService: FileService, public systemService: SystemService) {
  }

  public changeLanguage(language: string) {
    this.translate.use(language);
    this.translate.setDefaultLang(language);
  }

  checkLanguage(language: string): boolean {
    return language == this.translate.currentLang;
  }

  ngOnInit(): void {
    this.locationService.getLocationData(this.authService.getLocationId()).subscribe(
      res => this.locationService.locationData = res
    );
    this.username = this.authService.getTokenUsername();
    this.userId = this.authService.getTokenUserId();
    this.serveETickets = this.authService.getServeETicket() =="true";
    this.translate.use(this.translate.getDefaultLang());
    this.setSidebar();
  }

  setSidebar() {
    if (localStorage.getItem("isSidebarVisible") == undefined) {
      localStorage.setItem("isSidebarVisible", "true");
    }
    this.isSidebarVisible = JSON.parse(localStorage.getItem("isSidebarVisible"));
    if (localStorage.getItem("isSidebarToggled") == undefined) {
      localStorage.setItem("isSidebarToggled", "false");
    }
    this.isSidebarToggled = JSON.parse(localStorage.getItem("isSidebarToggled"));
  }

  onLogoutConfirm(confirmed: boolean) {
    if (confirmed) {
      this.logout();
    }
  }

  logout() {
    this.authService.onLogout();
    this.router.navigate(['login']);
  }

  changeSidebarToggled() {
    this.isSidebarToggled = !this.isSidebarToggled;
    if (this.isSidebarToggled) {
      localStorage.setItem("isSidebarToggled", "true");
      this.isSidebarVisible = false;
      localStorage.setItem("isSidebarVisible", "false");
    } else {
      localStorage.setItem("isSidebarToggled", "false");
    }
  }

  changeSidebarVisibility() {
    this.isSidebarVisible = !this.isSidebarVisible;
    if (this.isSidebarVisible) {
      localStorage.setItem("isSidebarVisible", "true");
    } else {
      localStorage.setItem("isSidebarVisible", "false");
    }
  }
}
