import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AutoGenerateReportDTO } from 'projects/qcloud-models/report/report-task';
import { ReportTemplate } from 'projects/qcloud-models/report/report-template';
import { ReportTemplateJson } from 'projects/qcloud-models/report/report-template-json';
import { TicketReportParams } from 'projects/qcloud-models/report/ticket-report-params';
import { TicketHistoryParams } from 'projects/qcloud-models/statistics/ticket-history-params';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AutoGenerateReportService {

  appBase: string;

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  constructor(private http: HttpClient, private authService: AuthService) { }

  getReportTasks(): Observable<AutoGenerateReportDTO[]> {
    return this.http.get<AutoGenerateReportDTO[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/autogeneratereports`);
  }

  setReportTask(autoGenerateReportDTO: AutoGenerateReportDTO) {
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/autogeneratereports`, autoGenerateReportDTO);
  }

  deleteReportTask(guid: string) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/autogeneratereports/` + guid);
  }
}
