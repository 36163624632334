import { ReservationQuestionAnswer } from "./reservation-question-answer";

export class TicketReservation {
    public id: number;
    public reservationTime: Date;
    public categoryId: number;
    public additionalReservationData: ReservationQuestionAnswer[];
    public code: string;
    public used: boolean;
    public length: number;
    public sendMailOnReservation: boolean;
    public sendSmsOnReservation: boolean;
    public forHourTicketId: number;
}