import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { DeviceConfiguration } from 'projects/qcloud-models/device/device-configuration';
import { QcloudConsoleService } from '../qcloud-console.service';
import { ScreenSizeConfigurationService, ScreenSizeType } from '../services/screen-size-configuration.service';

@Component({
  selector: 'lib-desk',
  templateUrl: './desk.component.html',
  styleUrls: ['./desk.component.css']
})
export class DeskComponent implements OnInit {
  ScreenSizeTypeEnum = ScreenSizeType;
  public screenSizeType: ScreenSizeType;

  @Input() public deviceConfiguration: DeviceConfiguration;

  constructor(public consoleService: QcloudConsoleService, private screenSizeConfigurationService: ScreenSizeConfigurationService) { }

  ngOnInit(): void {
    this.screenSizeConfigurationService.updateScreenSizeType(window.innerWidth, window.innerHeight);
    this.consoleService.configureConsole(this.deviceConfiguration.categories, this.deviceConfiguration.desks);
    this.screenSizeType = this.screenSizeConfigurationService.screenSizeType;

    this.consoleService.toastrTimeoutService.screenSizeType = this.screenSizeType;
    this.consoleService.saveScreenSizeService.updateScreenSize(window.innerHeight, window.innerWidth);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenSizeConfigurationService.updateScreenSizeType(window.innerWidth, window.innerHeight);
    this.consoleService.configureConsole(this.deviceConfiguration.categories, this.deviceConfiguration.desks);
    this.screenSizeType = this.screenSizeConfigurationService.screenSizeType;
    this.consoleService.toastrTimeoutService.screenSizeType = this.screenSizeType;
    this.consoleService.saveScreenSizeService.updateScreenSize(window.innerHeight, window.innerWidth);
  }

  checkLoadConsoleAssets() {
    return this.consoleService.desk && this.consoleService.user && this.consoleService.getAllConsoleCategories()
      && this.consoleService.getCategoriesToCallTicket();
  }
}
