import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { QcloudConsoleService } from '../../../qcloud-console.service';

@Component({
  selector: 'lib-call-eticket-button',
  templateUrl: './call-eticket-button.component.html',
  styleUrls: ['./call-eticket-button.component.css',  './../../base-button/base-button.component.css']
})
export class CallETicketButtonComponent  implements OnInit {
	@ViewChild('button') public button: ElementRef;isAddNoteEnabled: boolean;
isLocked: boolean;
  constructor(private consoleService: QcloudConsoleService) {
    this.consoleService.isLocked.subscribe((val) => {
      this.isLocked = val;
    });
  }

  ngOnInit(): void {
  }

  public callETicket() {
    this.consoleService.ticket.subscribe((val) =>{
      try{this.isAddNoteEnabled = this.consoleService.getAllConsoleCategories().filter(x => x.id == val.effectiveCategoryId)[0].isNoteRequired}catch{this.isAddNoteEnabled=false}
      if(this.isAddNoteEnabled){
        this.consoleService.openNoteTicketModal();
      }
    });
    this.consoleService.acceptAndCallTicket();

  }
}
