import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Category } from 'projects/qcloud-models/category/category.model';
import { timeout } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { CategoryGroup } from 'projects/qcloud-models/category/category-group.model';
import { AxxonIntelectIntegrationCategoryFacilityCode } from 'projects/qcloud-models/integration/axxon-intelect-integration';
import { AmmsCode, CodeType } from 'projects/qcloud-models/integration/amms-code';
import { AmmsCategoryMap } from 'projects/qcloud-models/integration/amms-category-mapping';
import { AmmsMappedReservation } from 'projects/qcloud-models/integration/amms-mapped-reservation';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
    providedIn: 'root'
})
export class AmmsIntegrationService {

    appBase: string;

    constructor(private http: HttpClient, private authService: AuthService) { }

    public setAppBase(appBase: string) {
        this.appBase = appBase;
    }

    getAmmsMappedReservations(startDate: Date, endDate: Date, callback: (data: AmmsMappedReservation[]) => any, onError: () => any) {
        const options = {
            "headers": {
                "startDate": startDate.toDateString(),
                "endDate": endDate.toDateString()
            }
        };
        this.http.get(this.appBase +
            `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/amms/reservations`, options
            )
            .pipe(timeout(DEFAULT_TIMEOUT))
            .subscribe(
                callback,
                onError
            );
    }

    overwriteAmmsMappedReservations(reservations: AmmsMappedReservation[], callback: (data: AmmsMappedReservation[]) => any, onError: () => any) {
        this.http.post(this.appBase +
            `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/amms/reservations`, reservations)
            .pipe(timeout(DEFAULT_TIMEOUT))
            .subscribe(
                callback,
                onError
            );
    }

    getCodesByType(codeType: CodeType, callback: (data: AmmsCode[]) => any, onError: () => any) {
        this.http.get(this.appBase +
            `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/amms/codes/${codeType}`)
            .pipe(timeout(DEFAULT_TIMEOUT))
            .subscribe(
                callback,
                onError
            );
    }

    saveCodeByType(codeType: CodeType, codes: AmmsCode[], callback: (data: AxxonIntelectIntegrationCategoryFacilityCode[]) => any, onError: () => any) {
        this.http.post(this.appBase +
            `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/amms/codes/${codeType}`, codes)
            .pipe(timeout(DEFAULT_TIMEOUT))
            .subscribe(
                callback,
                onError
            );
    }

    getMaps(callback: (data: AmmsCategoryMap[]) => any, onError: () => any) {
        this.http.get(this.appBase +
            `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/amms/maps`)
            .pipe(timeout(DEFAULT_TIMEOUT))
            .subscribe(
                callback,
                onError
            );
    }

    saveMaps(maps: AmmsCategoryMap[], callback: (data: AxxonIntelectIntegrationCategoryFacilityCode[]) => any, onError: () => any) {
        this.http.post(this.appBase +
            `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/amms/maps`, maps)
            .pipe(timeout(DEFAULT_TIMEOUT))
            .subscribe(
                callback,
                onError
            );
    }

    getDefaultCategoryId(callback: (data: number) => any, onError: () => any) {
        this.http.get(this.appBase +
            `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/amms/category`)
            .pipe(timeout(DEFAULT_TIMEOUT))
            .subscribe(
                callback,
                onError
            );
    }

    setDefaultCategoryId(defaultCategoryId: number, callback: () => any, onError: () => any) {
        this.http.put(this.appBase +
            `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/amms/category/` + defaultCategoryId, {})
            .pipe(timeout(DEFAULT_TIMEOUT))
            .subscribe(
                callback,
                onError
            );
    }
}
