import { Component, OnInit, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { take, takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';
import { ReservationService } from 'projects/qcloud-rest-client/src/lib/reservation.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ReservationGroupClientService } from 'projects/qcloud-rest-client/src/lib/reservation-group-client.service';
import { ReservationCategoryGroup } from 'projects/qcloud-models/reservation-category-group/reservation-category-group';
import { ReservationSchedule } from 'projects/qcloud-models/reservation/reservation-schedule';
import { WeekDay } from '@angular/common';
import { WeekService } from 'projects/qcloud-web/src/app/shared/week.service';
import { TimeConversionService } from 'projects/qcloud-web/src/app/shared/time-conversion.service';
import { ActivityTime } from 'projects/qcloud-models/activity-time';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { CategoryGroup } from 'projects/qcloud-models/category/category-group.model';

@Component({
  selector: 'app-reservation-group-by-categories',
  templateUrl: './reservation-group-by-categories.component.html',
  styleUrls: ['./reservation-group-by-categories.component.css']
})
export class ReservationGroupByCategoriesComponent implements OnInit {

  categories: Category[];
  categoryGroups: CategoryGroup[];
  newCategoryGroups: CategoryGroup[];
  protected _onDestroy = new Subject<void>();
  constructor(
    private categoryServiceClient: CategoryService, 
    private reservationGroupClient: ReservationGroupClientService, 
    private toastr: ToastrService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.categoryServiceClient.getCategories(
      (data: Category[]) => {
        this.categories = data.filter(x => x.canBeReserved);
        this.reservationGroupClient.getGroups(groups => {
          let converted = [];
          groups?.forEach(g => {
            var c: CategoryGroup = {
              id: g.id,
              name: g.name,
              locationId: 0,
              groupCategoriesIds: g.categories.map(y => y.id)
            }
            converted.push(c);
          });
          this.categoryGroups = converted;

        }, () => { })
      }, () => { }
    )
  }

  setReservationCategoryGroups(event: any) {
    this.newCategoryGroups = event;
    var data = [];
    event.forEach(group => {
      var d: any = {};
      d.id = group.id,
        d.name = group.name;
      d.categoryIds = group.groupCategoriesIds;
      data.push(d);
    });
    this.reservationGroupClient.setGroups(data, () => {
      this.translate.get('reservation-group-configuration-save-success').subscribe((error: string) => {
        this.translate.get('repeat').subscribe((res: string) => {
          this.toastr.success(res);
        });
      });
    }, () => {
      this.translate.get('reservation-group-configuration-save-error').subscribe((error: string) => {
        this.translate.get('repeat').subscribe((res: string) => {
          this.toastr.error(res, error);
        });
      });
    });
  }
}