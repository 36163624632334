import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { GetAvailableTimesParameter } from 'projects/qcloud-models/reservation/get-available-times-parameter';
import { ReservationLimit } from 'projects/qcloud-models/reservation/reservation-limit';
import { ReservationModuleConfiguration } from 'projects/qcloud-models/reservation/reservation-module-configuration';
import { ReservationMonthCapacity } from 'projects/qcloud-models/reservation/reservation-month-capacity';
import { ReservationQuestion } from 'projects/qcloud-models/reservation/reservation-questions';
import { ReservationSchedule } from 'projects/qcloud-models/reservation/reservation-schedule';
import { TicketReservation } from 'projects/qcloud-models/reservation/ticket-reservation';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})
export class OnHoursService {

  appBase: string;

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  constructor(private http: HttpClient, private authService: AuthService) { }

      // maybe independent service for OnHours should be made
      updateOnHoursCategories(ids: number[], callback: () => any, onError: () => any) {
        this.http.put(this.appBase +
          `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/onHours/categories`, ids)
          .pipe(timeout(DEFAULT_TIMEOUT))
          .subscribe(
            callback,
            onError
          );
      }

}
