import { Component, Input, OnInit } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { ReportTemplateParameter } from 'projects/qcloud-models/report/report-template-parameter';
import { User } from 'projects/qcloud-models/user/user.model';

@Component({
  selector: 'app-report-template',
  templateUrl: './report-template.component.html',
  styleUrls: ['./report-template.component.css']
})
export class ReportTemplateComponent implements OnInit {

  @Input() reportTemplateParameters: ReportTemplateParameter[];

  @Input() categories: Category[];  
  @Input() desks: Desk[];
  @Input() users: User[];

  constructor() { }

  ngOnInit(): void {
  }

  addParameter() {
    this.reportTemplateParameters.push(new ReportTemplateParameter());
  }

  deleteParameter(idx: number) {
    this.reportTemplateParameters.splice(idx, 1);
  }

}
