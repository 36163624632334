import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { QcloudConsoleService } from '../../qcloud-console.service';
import { TicketReservation } from 'projects/qcloud-models/reservation/ticket-reservation';
import { ReservationService } from 'projects/qcloud-rest-client/src/lib/reservation.service';

@Component({
  selector: 'lib-call-ticket-manual-modal',
  templateUrl: './call-ticket-manual-modal.component.html',
  styleUrls: ['./call-ticket-manual-modal.component.css']
})
export class CallTicketManualModalComponent implements OnInit {
  @Output() hideInteractionModel = new EventEmitter<boolean>();
  tickets: Ticket[];

  ticketReservationTimes = new Map<number, string>();
  serveETicket: boolean;
  ticketReservations: TicketReservation[] = [];
  constructor(private consoleService: QcloudConsoleService, private reservationService: ReservationService) {
    this.consoleService.tickets.subscribe((val) => {
      this.tickets = val;
      this.getTodayTicketReservation();
    });
  }

  public getTodayTicketReservation() {
    var today = new Date();
    const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
    const endfDay = today;
    endfDay.setHours(24,0,0,0);
    this.reservationService.getTicketsReservations(startOfDay, endfDay, (data) => {
        this.ticketReservations = data;
        this.updateTicketReservationTimes();
      }, () => {});
  }

  ngOnInit(): void {
    this.serveETicket = this.consoleService.user.hasPermissionToServeETickets;
  }

  jsonToNote(ticket: Ticket){
    return JSON.parse(ticket.metadataJson).Note;
  }

  jsonToNoteShort(ticket: Ticket){
    let text="";
    try{
      text = JSON.parse(ticket.metadataJson).Note;
      if(text.length > 20){
      text = text.substring(0, 20).concat('...');
      }
    }catch{}
      return text;
  }


  manualCallTicket(ticketId: number) {
    if (!this.serveETicket) {
      this.consoleService.callManualTicket(ticketId);
    } else {
      this.consoleService.callManualTicketWhileServedOther(ticketId);
    }
    this.hideInteractionModel.emit(true);
  }

  updateTicketReservationTimes() {
    this.tickets.forEach( ticket => {
      var ticketReservation = this.ticketReservations.find(reservation => reservation.forHourTicketId == ticket.id);
      if(!ticketReservation)
      {
        return '';
      }
      ticketReservation.reservationTime = new Date(ticketReservation.reservationTime);
      var hours = ticketReservation.reservationTime.getHours();
      var minutes = (ticketReservation.reservationTime.getMinutes()<10?'0':'') +  ticketReservation.reservationTime.getMinutes();

      this.ticketReservationTimes.set( ticket.id, " | " + hours + ":" + minutes);
    });

  }
  getTicketReservationTime(ticketId : number) : string {
    return this.ticketReservationTimes.get(ticketId);
  }

}
