import { Desk } from '../desk/desk';
import { Category } from '../category/category.model';
import { DeviceType } from './device-type.enum';
import { CategoryDisplayNameType } from './category-display-name-type.enum';
import { DeviceCustomization } from './device-customization.model';
import { Tree } from '../tree';
import { LocationLocale } from '../translation/location-locale';
import { ActivityTime } from '../activity-time';
import { DeviceGroupTranslation } from '../translation/device-group-translation.model';
import { CategoryTranslation } from '../translation/category-translation.model';
import { DeviceNetworkConfiguration } from './device-network-configuration';
import { PersonalisationTranslation } from '../translation/personalisation-translation';
import { TicketGenerationType } from './ticket-generation-type.enum';
import { Personalisation } from '../location/personalisation';
import { DeskTranslation } from '../translation/desk-translation.model';
import { TicketLayout } from '../location/ticket-layout.model';
import { TicketTranslation } from '../translation/ticket-translation';
import { ReservationButtonTextTranslation } from '../translation/reservation-button-text-translation';

export class DeviceConfiguration {
    public desks: Desk[];
    public categories: Category[];
    public locationCategories: Category[];
    public deviceDesks: Desk[];
    public deviceType: DeviceType;
    public deskCategoryId: number;
    public categoryDisplayNameType: CategoryDisplayNameType;
    public locationId: number;
    public systemId: number;
    public deskId: number;
    public ticketElements: number;
    public locationName: string;
    public locationAddress: string;
    public systemName: string;
    public layoutType: number;
    public canPrintReservedTickets: boolean;
    public canMakeReservations: boolean;
    public canShowAvgWaitTime: boolean;
    public canShowQueue: boolean;
    public showDeskIcon: boolean;
    public reservationButtonText: string;
    public reservationButtonTextTranslations: ReservationButtonTextTranslation[];
    public makeReservationButtonText: string;
    public multiLangSystem: boolean;
    public alwaysReadInMainLocale: boolean;
    public title: string;
    public categoryGroups: any[];
    public mainLocale: string;
    public deviceCategoryTree: Tree<Category>;
    public locales: LocationLocale[];
    public categoryTranslations: CategoryTranslation[];
    public categoryGroupTranslations: DeviceGroupTranslation[];
    public deviceCustomization: DeviceCustomization;
    public deviceNetworkConfiguration: DeviceNetworkConfiguration; //TODO: this could be get from config
    public logoImgPath: string;
    public personalisation: Personalisation;
    public personalisationTranslations: PersonalisationTranslation[];
    public ticketsLayout: TicketLayout;
    public ticketTranslations: TicketTranslation[];
    public deskTranslations: DeskTranslation[];
    public deviceActivityTimes: ActivityTime[];
    public ticketGenerationType: TicketGenerationType;
    public sayingTicketsType: SayingTicketsType;
    public deviceDeskIcons: DeviceDeskIcon[];
    public isWcag: boolean;
}

export enum SayingTicketsType {
    NoSound = 1,
    SimpleSound = 2,
    Read = 3
}

export class DeviceDeskIcon {
    deskId: number;
    imgPath: string;
}