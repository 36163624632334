<div *ngIf="locationService.getLocationConstraints().areEnabledTicketsReservations">
    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <h5 class="m-0 font-weight-bold text-primary">{{ 'reservation-calendar' | translate }}</h5>
        </div>
        <div class="card-body">
            <div>
                <mat-form-field>
                    <mat-label>{{ 'reservation-choosen-category' | translate }}</mat-label>
                    <mat-select matNativeControl
                        [(ngModel)]="choosenCategory">
                        <mat-option *ngFor="let category of categories" [value]="category">
                            {{ category.fullName }} {{ category.categoryTag }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <app-reservation-calendar [choosenCategory]="choosenCategory"></app-reservation-calendar>
        </div>
    </div>
</div>

<app-licence-info *ngIf="!locationService.getLocationConstraints().areEnabledTicketsReservations"
    [title]="'reservation-calendar'">
</app-licence-info>
<app-reservation-export-csv></app-reservation-export-csv>