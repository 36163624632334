import { ActivityTime } from "../activity-time";

export class Category {
    public id: number;
    public locationId: number;
    public fullName: string;
    public shortName: string;
    public categoryTag: string;
    public ticketLimit: number;
    public timePerTicket: number;
    public isBlocked: boolean;
    public unblockingTimeDaysValue: ActivityTime[];
    public ticketReservationBlockTimes: any[];
    public isAutomaticallyReset: boolean;
    public blockingMessageActivity: string;
    public blockingMessageManual: string;
    public isActive: boolean;
    public canBeReserved: boolean;
    public eTicketRedirect: boolean;
    public canMobile: boolean;
    public reservationIntervalMins: number;
    public maxServiceTime: number;
    public workingType: number;
    public avgTimeBlock: boolean;
    public avgTimeBlockText: string;
    public smsCategory: boolean;
    public sendSmsOnReservation: boolean;
    public sendSmsOnPrint: boolean;
    public sendSmsOnBeforeCall: boolean;
    public sendSmsOnTicketCall: boolean;
    public conditionalTicket: boolean;
    public conditionalTicketText: string;
    public additionalPrint: boolean;
    public additionalPrintText: string;
    public printedTicketAdditionalText: string;
    public defaultPauseTimeMins: number;
    public isNoteRequired: boolean;
    public ticketAmountToCountAverageWaitingServiceTime: number;
    public canOnHour: boolean;
}
