import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CategoryPriority } from 'projects/qcloud-models/category/category-priority.enum';
import { Category } from 'projects/qcloud-models/category/category.model';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { DeskCategoryPost } from 'projects/qcloud-models/desk/desk-category-post.model';
import { DeskCategory } from 'projects/qcloud-models/desk/desk-category.model';
import { DeskStatus } from 'projects/qcloud-models/desk/desk-status.enum';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';

@Component({
  selector: 'app-desk',
  templateUrl: './desk.component.html',
  styleUrls: ['./desk.component.css']
})
export class DeskComponent implements OnInit {

  desk: Desk;
  isEditForm: boolean;
  advancedMode: boolean = false;
  formSubmitted: boolean = false;
  categoryPriority = CategoryPriority;

  categories: Category[];
  protected deskCategories: DeskCategory[];
  protected initialDeskCategories: DeskCategory[];

  public deskCategoryMultiCtrl: UntypedFormControl;
  public deskCategoryMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredDeskCategoriesMulti: ReplaySubject<DeskCategory[]> = new ReplaySubject<DeskCategory[]>(1);

  protected _onDestroy = new Subject<void>();

  constructor(private deskService: DeskService, private categoryService: CategoryService,
    private authService: AuthService, private route: ActivatedRoute, private toastr: ToastrService,
    private router: Router, private translate: TranslateService) { }

  ngOnInit(): void {
    let deskId: number;
    this.route.params.subscribe(params => {
      deskId = params['id']
    });
    if (deskId) {
      this.isEditForm = true;
      this.getDesk(deskId);
    } else {
      this.isEditForm = false;
      this.initializeDesk();
    }
  }

  initializeDesk() {
    this.desk = new Desk();
    this.desk.locationId = +this.authService.getLocationId();
    this.desk.systemId = +this.authService.getSystemId();
    this.desk.categories = new Array();
    this.desk.deskStatus = DeskStatus.Closed;
    this.getCategories();
  }

  getDesk(deskId: number) {
    this.deskService.getDesk(deskId).subscribe(
      desk => {
        this.desk = desk;
        this.getCategories();
      }
    );
  }

  onSubmit(categories: DeskCategory[]) {
    this.formSubmitted = true;
    this.desk.categories = categories.map(category => new DeskCategoryPost(category));
    
    if (this.isEditForm) {
      this.editDesk();
    } else {
      this.addDesk();
    }
  }

  addDesk() {
    this.desk.id = 0;
    this.deskService.addDesk(this.desk).subscribe(
      res => {
        this.router.navigate(['/desks']);
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('desk-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  editDesk() {
    this.deskService.editDesk(this.desk, this.desk.id).subscribe(
      res => {
        this.router.navigate(['/desks']);
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('desk-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        }); 
      }
    );
  }

  getCategories() {
    this.categoryService.getCategories((data)=>{
      this.categories = data;
      this.deskCategories = this.categories.map(category => new DeskCategory(category));

      this.initialDeskCategories = new Array<DeskCategory>();
      for (let i = 0; i < this.deskCategories.length; i++) {
        for (let j = 0; j < this.desk.categories.length; j++) {
          if (this.deskCategories[i].categoryId == this.desk.categories[j].categoryId) {
            this.deskCategories[i].categoryPriority = this.desk.categories[j].categoryPriority;
            this.initialDeskCategories.push(this.deskCategories[i]);
          }
        }
      }
      this.deskCategoryMultiCtrl = new UntypedFormControl(this.initialDeskCategories);

      // load the initial deskCategory list
      this.filteredDeskCategoriesMulti.next(this.deskCategories.slice());

      // listen for search field value changes
      this.deskCategoryMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterdeskCategoriesMulti();
      });
    },
    ()=>{}
    );
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredDeskCategoriesMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.deskCategoryMultiCtrl.patchValue(val);
        } else {
          this.deskCategoryMultiCtrl.patchValue([]);
        }
      });
  }

  protected filterdeskCategoriesMulti() {
    if (!this.deskCategories) {
      return;
    }
    // get the search keyword
    let search = this.deskCategoryMultiFilterCtrl.value;
    if (!search) {
      this.filteredDeskCategoriesMulti.next(this.deskCategories.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the deskCategories
    this.filteredDeskCategoriesMulti.next(
      this.deskCategories.filter(deskCategory => deskCategory.name.toLowerCase().indexOf(search) > -1)
    );
  }

  setCategoryPriority(deskCategory: DeskCategory, priority: number) {
    deskCategory.categoryPriority = priority;
  }

}
