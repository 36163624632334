import { Component, OnDestroy, OnInit } from '@angular/core';
import { User } from 'projects/qcloud-models/user/user.model';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { QcloudStompClientService } from 'projects/qcloud-stomp-client/src/public-api';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { Subscription } from 'rxjs';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { ConsoleRestService } from 'projects/qcloud-rest-client/src/lib/console-rest.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, OnDestroy {

  users: User[];
  selectedUserId: string;
  public desks: Desk[];
  private stompDesksStatusSubscription: Subscription;
  private emptyDesk: string = "---";

  constructor(private userService: UserService,
    private translate: TranslateService,
    private qcloudStompClientService: QcloudStompClientService,
    private toastr: ToastrService,
    private deskService: DeskService,
    private consoleRestService: ConsoleRestService) { }

  ngOnInit(): void {
    this.getDesks();
  }

  ngOnDestroy(): void {
    this.stompDesksStatusSubscription.unsubscribe();
  }

  getUsers() {
    this.userService.getUsers((data) => {
      this.users = data;
      this.startDeskListening();
    },
      () => { }
    );
  }

  getDesks() {
    this.deskService.getDesks((data) => {
      this.getUsers();
      this.desks = data;
    },
      () => { });
  }

  private startDeskListening() {
    var context = this;
    this.stompDesksStatusSubscription = this.qcloudStompClientService.subscribe("/exchange/deskStatus/1.1.*", (payload: any) => {
      let desk = context.desks.find(x => x.id == payload.deskId);
      desk.deskStatus = payload.deskStatus;
      desk.currentLoggedInUserId = payload.userId;
      context.refreshTable();
    });
  }

  private refreshTable() {
    this.users = Object.assign([], this.users);
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
        this.deleteUser(this.selectedUserId);
    }
  }

  deleteUser(userId: string) {
    this.userService.deleteUser(userId).subscribe(
      res => {
        this.getUsers();
      },
      err => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  changeUserActivity(user: User) {
    this.userService.changeActivity(user.id).subscribe(
      res => {
        this.getUsers();
        this.translate.get('change-activity').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.translate.get('change-activity-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  getCurrentDesk(userId: string) {
    let desk = this.desks.find(x => x.currentLoggedInUserId == userId);
    if (desk) {
      return desk.fullName;
    }
    else {
      return this.emptyDesk;
    }
  }
}