import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseActivationData } from 'projects/qcloud-models/system/base-activation-data.model';
import { SystemVersion } from 'projects/qcloud-models/system/system-version';
import { TenantConfig } from 'projects/qcloud-models/system/tenant-config.model';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  getTenant(): Observable<TenantConfig> {
    return this.http.get<TenantConfig>(
      `${this.appBase}api/${this.authService.getSystemId()}/system`);
  }

  updateTenant(tenant: TenantConfig) {
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/system`, tenant);
  }

  activate(baseActivationData: BaseActivationData): Observable<TenantConfig> {
    return this.http.post<TenantConfig>(
      `${this.appBase}api/${this.authService.getSystemId()}/system/activate`, baseActivationData);
  }

  getSystemVersion(): Observable<SystemVersion> {
    return this.http.get<SystemVersion>(`${this.appBase}api/${this.authService.getSystemId()}/system/version`);
  }
}
