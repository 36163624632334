<div class="card-body">
    <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>{{ 'license-end' | translate }}</th>
                    <th>{{ 'ticket-reservation' | translate }}</th>
                    <th>{{ 'work-schedule' | translate }}</th>
                    <th>{{ 'consumer-survey' | translate }}</th>
                    <th>{{ 'sms-notifications' | translate }}</th>
                    <th>{{ 'mobile-client' | translate }}</th>
                    <th>{{ 'eticket' | translate }}</th>
                    <th>{{ 'on-hours' | translate }}</th>
                    <th>{{ 'devices' | translate }}</th>
                    <th>{{ 'locations' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ tenant.constraints.expirationDate | date:"dd/MM/yyyy" }}</td>
                    <td>
                        <app-confirm-reject-button [confirm]="tenant.constraints.isEnabled['TicketsReservations']">
                        </app-confirm-reject-button>    
                    </td>                      
                    <td>
                        <app-confirm-reject-button [confirm]="tenant.constraints.isEnabled['WorkerSchedule']">
                        </app-confirm-reject-button>
                    </td>
                    <td>
                        <app-confirm-reject-button [confirm]="tenant.constraints.isEnabled['ClientSurvey']">
                        </app-confirm-reject-button>
                    </td>
                    <td>
                        <app-confirm-reject-button [confirm]="tenant.constraints.isEnabled['Sms']">
                        </app-confirm-reject-button>
                    </td>                      
                    <td>
                        <app-confirm-reject-button [confirm]="tenant.constraints.isEnabled['Mobile']">
                        </app-confirm-reject-button>
                    </td>
                    <td>
                        <app-confirm-reject-button [confirm]="tenant.constraints.isEnabled['ETicket']">
                        </app-confirm-reject-button>
                    </td>
                    <td>
                        <app-confirm-reject-button [confirm]="tenant.constraints.isEnabled['OnHours']">
                        </app-confirm-reject-button>
                    </td>
                    <td>{{ tenant.constraints.countLimit['Devices'] }}</td>                      
                    <td>{{ tenant.constraints.countLimit['Locations'] }}</td>
                  </tr>
            </tbody>
        </table>
    </div>
    <div class="form-group">
        <div class="font-weight-bold">{{ 'new-license-code' | translate }}</div>
        <div class="input-group">
            <input name="activationCode" #activationCode="ngModel" [(ngModel)]="baseActivationData.activationCode"
                class="form-control">
        </div>
        <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted" (click)="activateLicense()">
            <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                aria-hidden="true"></span>{{ 'send' | translate }}  
        </button>
    </div>
</div>
