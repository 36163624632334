import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DateRange, DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY,  } from '@angular/material/datepicker';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.css'],
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
})
export class DateRangePickerComponent implements OnInit {

  @Input() startDate: Date;
  @Input() endDate: Date;
  @Output() rangeToEmit = new EventEmitter();

  selectedDateRange: DateRange<Date>;

  constructor(private translate: TranslateService, private _adapter: DateAdapter<any>, private cdr: ChangeDetectorRef) {
    this._adapter.setLocale(this.translate.currentLang);
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this._adapter.setLocale(event.lang);
      this.cdr.detectChanges();
    });
  }

  ngOnInit(): void {
    this.selectedDateRange = new DateRange(this.startDate, this.endDate);
  }

  _onSelectedChange(date: Date): void {
    if (this.selectedDateRange && this.selectedDateRange.start && date > this.selectedDateRange.start && !this.selectedDateRange.end) {
      this.selectedDateRange = new DateRange(this.selectedDateRange.start, date);
      this.rangeToEmit.emit([this.selectedDateRange.start, date]);
    } else {
      this.selectedDateRange = new DateRange(date, null);
      let endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
      this.rangeToEmit.emit([date, endDate]);
    }
  }

}
