import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'projects/qcloud-models/category/category.model';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { FileType } from 'projects/qcloud-models/report/file-type.enum';
import { ReportTemplate } from 'projects/qcloud-models/report/report-template';
import { QCloudElement, ReportTemplateParameter } from 'projects/qcloud-models/report/report-template-parameter';
import { TicketReportParams } from 'projects/qcloud-models/report/ticket-report-params';
import { User } from 'projects/qcloud-models/user/user.model';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';
import { ReportService } from 'projects/qcloud-rest-client/src/lib/report.service';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';

@Component({
  selector: 'app-report-statistics',
  templateUrl: './report-statistics.component.html',
  styleUrls: ['./report-statistics.component.css']
})
export class ReportStatisticsComponent implements OnInit {

  ticketReportParams: TicketReportParams = new TicketReportParams();
  FileType = FileType;
  generatingReport: boolean = false;

  reportTemplates: ReportTemplate[];
  reportTemplateParameters: ReportTemplateParameter[];

  categories: Category[];
  desks: Desk[];
  users: User[];

  categoriesToTemplate: QCloudElement[];
  desksToTemplate: QCloudElement[];

  constructor(private reportService: ReportService, private categoryService: CategoryService,
    private userService: UserService, private deskService: DeskService, public locationService: LocationService,
    private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.categoryService.getCategories(
      (data) => {
        this.categories = data
        this.categoriesToTemplate = this.categories.map(category => new QCloudElement(category.id, category.fullName));
      },
      () => {}
    );
    this.deskService.getDesks(
      (data) => {
        this.desks = data;
        this.desksToTemplate = this.desks.map(desk => new QCloudElement(desk.id, desk.fullName));
      },
      () => {}
    );
    this.userService.getUsers(
      (data) => { this.users = data },
      () => {}
    );
    this.getTemplates();
  }

  getTemplates() {
    this.reportService.getTemplates().subscribe(
      res => {
        this.reportTemplates = new Array<ReportTemplate>();
        res.forEach(element => {
          let reportTemplate = JSON.parse(element.reportTemplateJson) as ReportTemplate;
          reportTemplate.id = element.id;
          this.reportTemplates.push(reportTemplate);
        });
      },
      err => {}
    );
  }

  assignTemplateParameters(x: ReportTemplateParameter[]) {
    this.reportTemplateParameters = x;
  }

  generateReport() {
    this.generatingReport = true;
    this.ticketReportParams.templateParameters = this.reportTemplateParameters;
    this.ticketReportParams.reportBeginTime = new Date(
      this.ticketReportParams.reportBeginTime.getFullYear(), this.ticketReportParams.reportBeginTime.getMonth(),
      this.ticketReportParams.reportBeginTime.getDate(), 12, 0);
    this.ticketReportParams.reportEndTime = new Date(
      this.ticketReportParams.reportEndTime.getFullYear(), this.ticketReportParams.reportEndTime.getMonth(),
      this.ticketReportParams.reportEndTime.getDate(), 12, 0);
    this.ticketReportParams.categories = this.categoriesToTemplate;
    this.ticketReportParams.desks = this.desksToTemplate;
    this.reportService.generateReport(this.ticketReportParams).subscribe(
      res => {
        this.generatingReport = false;
        var downloadURL = window.URL.createObjectURL(res);
        var link = document.createElement('a');
        link.href = downloadURL;

        let fileName = "report";
        switch (this.ticketReportParams.fileType) {
          case FileType.Pdf:
            fileName += ".pdf";
            break;
          case FileType.Excel:
            fileName += ".xlsx";
            break;
          case FileType.Csv:
            fileName += ".csv"
            break;
          case FileType.Xml:
            fileName += ".xml"
            break;
          case FileType.Json:
            fileName += ".json"
            break;
          default:
            break;
        }
        link.download = fileName;
        link.click();
       },
       err => {
         this.generatingReport = false;
         this.translate.get('generate-report-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
       }
    );
  }

  getRange(range: Date[]) {
    this.ticketReportParams.reportBeginTime = range[0];
    this.ticketReportParams.reportEndTime = range[1];
  }

}
