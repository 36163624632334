<div #pagetop class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 *ngIf="!isEditForm" class="m-0 font-weight-bold text-primary">{{ 'add-user' | translate }}</h5>
        <div *ngIf="isEditForm" class="row">
            <h5 class="m-0 col-8 font-weight-bold text-primary">{{ 'edit-user' | translate }}</h5>
            <div class="col-4 text-right">
                <button type="button" class="btn btn-warning btn-circle btn-sm" tooltipClass="tooltip-warning"
                    matTooltip="{{ 'reset-password-button' | translate }}" (click)="resetPassword(user.id)">
                    <i class="fas fa-fw fa-sync"></i>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="user && categoryMultiCtrl" class="card-body">
        <form #form="ngForm" autocomplete="off">

            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        *{{ 'first-name' | translate }}
                        <div class="input-group">
                            <input name="firstName" #firstName="ngModel" [(ngModel)]="user.firstName"
                                class="form-control" required (change)="createUsername()">
                        </div>
                        <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
                            class="text-danger mx-2 my-1">
                            <div *ngIf="firstName.errors.required">{{ 'first-name-required' | translate }}</div>
                        </div>
                    </div>
                    <div class="col-6">
                        *{{ 'last-name' | translate }}
                        <div class="input-group">
                            <input name="lastName" #lastName="ngModel" [(ngModel)]="user.lastName" class="form-control"
                                required (change)="createUsername()">
                        </div>
                        <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
                            class="text-danger mx-2 my-1">
                            <div *ngIf="lastName.errors.required">{{ 'last-name-required' | translate }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                *{{ 'username' | translate }}
                <div class="input-group">
                    <input name="userName" #userName="ngModel" [(ngModel)]="user.userName" class="form-control"
                        required>
                </div>
                <div *ngIf="userName.invalid && (userName.dirty || userName.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="userName.errors.required">{{ 'username-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                {{ 'email' | translate }}
                <div class="input-group">
                    <input type="email" name="email" #email="ngModel" [(ngModel)]="user.email" class="form-control"
                        email>
                </div>
                <div *ngIf="email.invalid && (email.dirty || email.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="email.errors">{{ 'invalid-email' | translate }}</div>
                </div>
            </div>

            <app-served-categories *ngIf="categoryMultiCtrl" [categoryMultiCtrl]="categoryMultiCtrl"
                [categories]="categories" [title]="servedCategories" [noSelectedCategories]="noSelectedCategories"
                [displayCategories]="true">
            </app-served-categories>

            <mat-form-field>
                <mat-label>{{ 'role' | translate }}</mat-label>
                <mat-select matNativeControl name="role" #role="ngModel" [(ngModel)]="user.role" required>
                    <mat-option *ngIf="authService.isSuperAdmin()" value="SuperAdministrator">
                        {{ 'super-administrator' | translate }}
                    </mat-option>
                    <mat-option *ngIf="authService.isAdminOrHigher()" value="Administrator">
                        {{ 'administrator' | translate }}
                    </mat-option>
                    <mat-option *ngIf="authService.isManagerOrHigher()" value="Manager">
                        {{ 'manager' | translate }}
                    </mat-option>
                    <mat-option value="Employee">
                        {{ 'employee' | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div *ngIf="!serveETickets">

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox color="primary" name="hasAccessToConsole" #hasAccessToConsole="ngModel"
                            [(ngModel)]="user.hasAccessToConsole">{{ 'access-to-console' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox color="primary" name="manualCategoryCalling" #manualCategoryCalling="ngModel"
                            [(ngModel)]="user.manualCategoryCalling" [disabled]="!user.hasAccessToConsole">
                            {{ 'manual-category-calling' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox name="canGenerateTicketsInConsole" #canGenerateTicketsInConsole="ngModel"
                            [(ngModel)]="user.canGenerateTicketsInConsole" color="primary"
                            [disabled]="!user.hasAccessToConsole">
                            {{ 'console-ticket-generation' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox name="hasPermissionToCallingTicketsManually"
                            #hasPermissionToCallingTicketsManually="ngModel"
                            [(ngModel)]="user.hasPermissionToCallingTicketsManually" color="primary"
                            [disabled]="!user.hasAccessToConsole">
                            {{ 'manual-ticket-selection-for-recall' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox name="hasPermissionToBlockingCategoryManually"
                            #hasPermissionToBlockingCategoryManually="ngModel"
                            [(ngModel)]="user.hasPermissionToBlockingCategoryManually" color="primary"
                            [disabled]="!user.hasAccessToConsole">
                            {{ 'category-blocking' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox *ngIf="locationService.getLocationConstraints().areEnabledTicketsReservations"
                            name="hasPermissionToReserveTickets" #hasPermissionToReserveTickets="ngModel"
                            [(ngModel)]="user.hasPermissionToReserveTickets" color="primary">
                            {{ 'permission-to-reserve-tickets' | translate }}
                        </mat-checkbox>

                        <mat-checkbox *ngIf="!locationService.getLocationConstraints().areEnabledTicketsReservations"
                            disabled matTooltipPosition="right" matTooltipClass="tooltip-orange"
                            matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                            {{ 'permission-to-reserve-tickets' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox *ngIf="locationService.getLocationConstraints().isWorkersScheduleEnabled"
                            name="hasPermissionToConfigureGrafik" #hasPermissionToConfigureGrafik="ngModel"
                            [(ngModel)]="user.hasPermissionToConfigureGrafik" color="primary">
                            {{ 'permission-to-configure-grafik-schedule' | translate }}
                        </mat-checkbox>

                        <mat-checkbox *ngIf="!locationService.getLocationConstraints().isWorkersScheduleEnabled"
                            disabled matTooltipPosition="right" matTooltipClass="tooltip-orange"
                            matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                            {{ 'permission-to-configure-grafik-schedule' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox *ngIf="locationService.getLocationConstraints().areETicketsEnabled"
                            name="hasPermissionToServeETickets" #hasPermissionToServeETickets="ngModel"
                            [(ngModel)]="user.hasPermissionToServeETickets" color="primary">
                            {{ 'permission-to-serve-etickets' | translate }}
                        </mat-checkbox>

                        <mat-checkbox *ngIf="!locationService.getLocationConstraints().areETicketsEnabled" disabled
                            matTooltipPosition="right" matTooltipClass="tooltip-orange"
                            matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                            {{ 'permission-to-serve-etickets' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

            </div>

            <div class="form-group row">
                <button class="btn btn-danger m-1" routerLink="/users">{{ 'back' | translate }}</button>
                <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid"
                    (click)="onSubmit(categoryMultiCtrl?.value); formSubmitted=true">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>
                    {{ 'save-user' | translate }}
                </button>
                <button *ngIf="isEditForm" class="btn btn-info m-1" type="submit"
                    [disabled]="formSubmitted || form.invalid"
                    (click)="newBasedOnExisting(); pagetop.scrollIntoView({behavior: 'smooth'})">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>
                    {{ 'add-new-user-based-on-existing' | translate }}
                </button>
            </div>

            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>