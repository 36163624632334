import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DatePipe } from '@angular/common';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import {CalendarEventTitleFormatter, CalendarDateFormatter} from 'angular-calendar';
import { Category } from 'projects/qcloud-models/category/category.model';
import { TicketReservation } from 'projects/qcloud-models/reservation/ticket-reservation';
import { ReservationService } from 'projects/qcloud-rest-client/src/lib/reservation.service';
import { ReservationTitleFormatter } from 'projects/qcloud-web/src/app/page/reservation/reservation-calendar/reservation-title-formatter.provider';
import { ScheduleDateFormatter } from 'projects/qcloud-web/src/app/shared/schedule-date-formatter.provider';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { TimeCell } from './time-cell';
import { RedirectAction } from '../redirect-to-category-modal/redirect-action.enum';
import { QcloudConsoleService } from '../../qcloud-console.service';
import { ScreenSizeConfigurationService, ScreenSizeType } from '../../services/screen-size-configuration.service';

@Component({
  selector: 'lib-for-hour-chooser',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './for-hour-chooser.component.html',
  styleUrls: ['./for-hour-chooser.component.css'],
  providers: [

    DatePipe,
    {
      provide: CalendarEventTitleFormatter,
      useClass: ReservationTitleFormatter,
    },
    {
      provide: CalendarDateFormatter,
      useClass: ScheduleDateFormatter,
    }
  ],
  encapsulation: ViewEncapsulation.None
})

export class ForHourChooserComponent implements OnInit {

  redirectAction : RedirectAction = RedirectAction.ToForHour;
  modalAvalaibleTimes: TicketReservation[];
  locale: string;
  ticket: Ticket;
  ticketReservations: TicketReservation[];
  categories: Category[];
  timeCells : TimeCell[] = [];
  ScreenSizeTypeEnum;
  public screenSizeType: ScreenSizeType;

  additionalInfoShow: boolean = false;
  _selectedCategory: Category;
  set selectedCategory(value: Category) {
    this._selectedCategory = value;
  }

  get selectedCategory() : Category {
    return this._selectedCategory;
  }

  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private reservationService: ReservationService,
    private datePipe: DatePipe,
    private consoleService: QcloudConsoleService,
    private screenSizeConfigurationService: ScreenSizeConfigurationService
    ) {
      this.screenSizeType = screenSizeConfigurationService.screenSizeType;
      this.ScreenSizeTypeEnum = screenSizeConfigurationService.ScreenSizeTypeEnum;

      consoleService.getLastTicket();
      this.categories = consoleService.getAllConsoleCategories();
      this.selectedCategory = this.consoleService.selectedCategory;
      consoleService.ticket.subscribe((val: Ticket) => {
          this.ticket = val;
          this.getListForNextDays(1);
          this.cdr.detectChanges();
      });

      this.locale = this.translate.currentLang;
      translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.locale = event.lang;
      });
  }

  ngOnInit(): void {}

  private setTodayReservationTime(time : string) : Date
  {
    var hour =  parseInt(time.split(':')[0]);
    var minutes =  parseInt(time.split(':')[1]);

    var today = new Date();
    return new Date(today.getFullYear(), today.getMonth(), today.getDate(), hour, minutes, 0);
  }

  onReservationTimeClick(timeCell : TimeCell): void {
    var time = timeCell.time;
    var reservationDatetime =  this.setTodayReservationTime(time);
    if(reservationDatetime < new Date()) {
      return;
    }
    var reservation = new TicketReservation();
    reservation.reservationTime = reservationDatetime;
    reservation.categoryId = this.selectedCategory.id;
    reservation.additionalReservationData = [];
    reservation.used = false;
    reservation.length = timeCell.length;
    reservation.forHourTicketId = this.ticket.id;
    this.consoleService.redirectTicketToForHour(reservation);
  }

  getListForNextDays(dayCount: number) {
    let date = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    this.reservationService.getReservationListForNextDays(this.selectedCategory.id, date, dayCount, (data) => {
      this.ticketReservations = data;
      this.refillCalendarEvent();
    }, () => {
     });
  }

  refillCalendarEvent() {
    var cells : TimeCell[] = [];
    this.ticketReservations.forEach(reservation => {
      let reservationTime = new Date(reservation.reservationTime);
      if (reservation.code == null && reservationTime > new Date() && reservationTime.getDate() == new Date().getDate()) {
          var hourMinuteSeccond = reservationTime.toTimeString().split(' ')[0];
          var hour = hourMinuteSeccond.split(':')[0];
          var minutes = hourMinuteSeccond.split(':')[1];
          var timeCell = new TimeCell();
          timeCell.time = hour+':'+ minutes;
          timeCell.length = reservation.length;
          cells.push(timeCell);
      }
    });

    this.timeCells = cells;
    if(this.timeCells.length == 0) {
      this.additionalInfoShow = false;
    } else {
      this.additionalInfoShow = true;
    }
    this.cdr.detectChanges();
  }
}
