import { Component, OnInit } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { validateHorizontalPosition } from '@angular/cdk/overlay';

@Component({
  selector: 'lib-display-ticket',
  templateUrl: './display-ticket.component.html',
  styleUrls: ['./display-ticket.component.css']
})
export class DisplayTicketComponent implements OnInit {
  ticketCaption: string;
  ticket: Ticket;
  constructor(consoleService: QcloudConsoleService) {
    consoleService.ticket.subscribe((val: Ticket) => { this.ticket = val; this.ticketCaption = val?.caption });
  }

  ngOnInit(): void {

  }

}

