<div *ngIf="areETicketsEnabled && locationData" class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'eticket' | translate }}</h5>
    </div>
    <div class="card-body">
        <div class="form-group">
            <div class="item-group">
                <mat-checkbox name="eticketChangeCodeAfter24h" #eticketChangeCodeAfter24h="ngModel"
                    [(ngModel)]="locationData.eticketChangeCodeAfter24h" color="primary">
                    {{ 'eticket-change-code-after-24-hours' | translate }}
                </mat-checkbox>
            </div>

            <button class="btn btn-primary btn-sm" (click)="editLocation()">
                {{ 'save' | translate }}</button>
        </div>

        <div class="form-group">
                <qrcode [qrdata]="code" cssClass="qrcode" [errorCorrectionLevel]="correctionLevel" elementType="elementType"></qrcode>
            <button class="btn btn-primary mr-2" (click)="generateNewCode()">
                {{ 'generate-new-qr-code' | translate }}</button>
            <button class="btn btn-info" (click)="downloadAsPDF()">
                {{ 'download-qr-code' | translate }}</button>
        </div>
    </div>
</div>

<app-licence-info *ngIf="!areETicketsEnabled" [title]="'eticket'">
</app-licence-info>

<div style="display: none">
    <div id="pdf" #pdf>
        <table style="text-align: center;">
            <tr>
                <td><img src="assets/images/pdf-text-1.png" style="height: 60px;"></td>
            </tr>
            <tr>
                <td>
                    <qrcode [qrdata]="code" cssClass="qrcodepdf" [errorCorrectionLevel]="correctionLevel" elementType="elementType"></qrcode>
                </td>
            </tr>
        </table>
        <table>
            <tr>
                <td style="padding-left: 10px;"><img src="assets/images/pdf-text-2.png" style="height: 18px;"></td>
                <td rowspan="2"><img src="assets/images/tel-bilet.png" style="height: 80px;"></td>
            </tr>
            <tr>
                <td>
                    <img src="assets/images/QCloud.png" style="width: 100px; padding-top: 10px; padding-left: 10px; margin-right: 50px;">
                </td>
            </tr>
        </table>
    </div>
</div>