import { Component, OnInit, Input } from '@angular/core';
import { DeviceConfiguration } from 'projects/qcloud-models/device/device-configuration';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { ConsoleLogin } from 'projects/qcloud-models/user/console-login.model';
import { QcloudConsoleService } from '../qcloud-console.service';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';
import { User } from 'projects/qcloud-models/user/user.model';
import { ProjectType } from '../qcloud-console.component';

@Component({
  selector: 'lib-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @Input() public deviceConfiguration: DeviceConfiguration;

  public choosenDesk: Desk;
  public deskList: Desk[];
  public userList: User[];
  public choosenUser: User;
  public isLoggedIn = false;
  public username = "";
  public password = "";
  isLoginByWEB = false;
  private LAST_LOGGED_USERID_KEY = "LAST_LOGGED_USERID_KEY";
  projectTypeEnum = ProjectType;

  constructor(private consoleService: QcloudConsoleService, public authService: AuthService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.deskList = this.deviceConfiguration.desks;
    let deskId = this.authService.getDeskId();
    if (deskId) {
      this.choosenDesk = this.deskList.find(x => x.id == deskId);
    }
    else {
      this.choosenDesk = this.deskList[0];
    }
    // this.isLoggedIn = !(this.authService.getTokenUserId() == undefined);

    let locationId = this.deviceConfiguration.locationId;

    this.userService.getUsers(users => {
      this.userList = users;
      //let lastUserId = this.getLastLoggedUserId();
      //if(lastUserId){
      //  this.choosenUser = users.filter(x=>x.id == lastUserId)[0];
      //}
      //else{
      //  this.choosenUser = users[0];
      //}
    }, () => {

    });

    var loggedUserName = this.authService.getTokenUsername();
    if (this.consoleService.projectType == this.projectTypeEnum.WEB && loggedUserName) {
      this.username = loggedUserName;
      this.isLoginByWEB = true;
    }
  }

  public getLastLoggedUserId(): string {
    let item = localStorage.getItem(this.LAST_LOGGED_USERID_KEY)
    return item;
  }

  public setLastLoggedUserId(value: string) {
    localStorage.setItem(this.LAST_LOGGED_USERID_KEY, value);
  }

  public loginToConsole() {
    let consoleLogin = new ConsoleLogin();
    consoleLogin.deskId = this.choosenDesk.id;
    //let userGuid = "";
    //if (this.isLoggedIn) {
    //  userGuid = this.authService.getTokenUserId();
    //}
    //else {
    // userGuid = this.choosenUser.id;
    consoleLogin.password = this.password;
    consoleLogin.userName = this.username;
    //}
    this.consoleService.loginToConsole(consoleLogin);
    //this.setLastLoggedUserId(userGuid);
    this.password = "";
    this.userService.updateStateChanged(true);
  }
}
