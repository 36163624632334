import { Component, OnInit } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { QcloudConsoleService } from '../../qcloud-console.service';
import { UserService } from '../../../../../qcloud-rest-client/src/lib/user.service';
import { UserCategory } from '../../../../../qcloud-models/userCategory/userCategory.model';

@Component({
  selector: 'lib-served-categories-modal',
  templateUrl: './served-categories-modal.component.html',
  styleUrls: ['./served-categories-modal.component.css']
})
export class ServedCategoriesModalComponent implements OnInit {

  allConsoleCategories: Category[];
  categoriesToCallTicket: Category[];
  availableUserCategories: UserCategory[];

  constructor(private consoleService: QcloudConsoleService, private userService: UserService) {
    userService.getUserCategory(consoleService.user.id).subscribe(userCategories => {
      this.availableUserCategories = userCategories;
      this.categoriesToCallTicket = consoleService.getCategoriesToCallTicket();
    }
    );

  }

  ngOnInit(): void {
  }

  changeCategory(category: Category) {
    var categoryIndex = this.availableUserCategories.findIndex(c => c.categoryId == category.id);
    this.availableUserCategories[categoryIndex].isServedCategory = !this.availableUserCategories[categoryIndex].isServedCategory;
    this.userService.updateUserCategoryByUser(this.availableUserCategories[categoryIndex])
      .subscribe(
        res => {
          this.availableUserCategories = res;
        },
        err => { }
      );
  }

  isAvailable(categoryId: number): boolean {
    return this.availableUserCategories.find(c => c.categoryId == categoryId && c.isServedCategory == true) != null;
  }

}
