import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { QueueState } from 'projects/qcloud-models/console/queueState';
import { TicketsType } from 'projects/qcloud-models/console/ticketsType.enum';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-queue-state',
  templateUrl: './queue-state.component.html',
  styleUrls: ['./queue-state.component.css']
})

export class QueueStateComponent implements OnInit {
  @Output() showInteractionModel = new EventEmitter<boolean>();
  queueState: QueueState;
  categories: Category[];
  isEnabled: boolean;
  isBlocked: boolean;
  serveETickets: boolean;
  @Input() public categorieShowLimits: number;

  @ViewChild('divQueueState') divQueueState: ElementRef;
  @ViewChild('spanFaReplyIcon') spanFaReplyIcon: ElementRef;
  @ViewChild('spanFaReplyText') spanFaReplyText: ElementRef;
  @ViewChild('spanFaReply') spanFaReply: ElementRef;
  @ViewChildren('spanCategoryTags') public spanCategoryTags: QueryList<ElementRef>;
  @ViewChildren('spanCategoryStates') public spanCategoryStates: QueryList<ElementRef>;
  @ViewChildren('spanCategories') public spanCategories: QueryList<ElementRef>;


  constructor(private consoleService: QcloudConsoleService) {
    this.consoleService.queueState.subscribe((val) =>
    {
      this.queueState = val;
      this.categories = this.consoleService.getCategoriesToCallTicket().filter(x => !x.eTicketRedirect);
    });
    this.consoleService.ticket.subscribe((val) => {
      if (val && val.id != 0 && val.id != -1) {
        this.isEnabled = false;
      }
      else {
        this.isEnabled = true;
      }
    });
    this.consoleService.isBlocked.subscribe((val) => {
      this.isBlocked = val;
      this.consoleService.closeAllModals();
    });
    this.categories = this.consoleService.getCategoriesToCallTicket().filter(x => !x.eTicketRedirect);
    this.categorieShowLimits = this.categorieShowLimits ? this.categorieShowLimits : this.categories.length;
  }

  ngOnInit(): void {
    this.serveETickets = this.consoleService.user.hasPermissionToServeETickets;
  }

  getCategories() : Category[] {
    if(this.categories != undefined && this.queueState != undefined) {
      this.categories = this.categories.sort((a, b) => (
        this.queueState.queueStateByCategory.get(a.id) > this.queueState.queueStateByCategory.get(b.id) ? -1 : 1)
      );
    }
    return this.categories;
  }

  public manualCallTicket(categoryId: number) {
    if (this.serveETickets || !this.consoleService.user.hasPermissionToCallingTicketsManually) return;
    if (this.isEnabled) {
      if (categoryId == -1) {
        this.consoleService.openManualCallTicketModal(TicketsType.Redirected);
      } else {
        this.consoleService.openManualCallTicketModal(TicketsType.Category, categoryId);
      }
    }
    else {
      this.consoleService.setIsBlockedStatus(true);
    }
    this.showInteractionModel.emit(true);
  }
}
