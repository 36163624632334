<div class="form-group" *ngIf="locationActivityBlocks">
    <div class="font-weight-bold mb-1">
        {{ 'blocking-category-activities' | translate }}
        <button *ngIf="!addingNewActivityBlock" class="btn btn-primary btn-circle btn-sm"
            (click)="editActivityBlock(newActivityBlock); addingNewActivityBlock = true"
            matTooltip="{{ 'add' | translate }}" tooltipClass="tooltip-primary">
            <i class="fas fa-plus"></i>
        </button>
    </div>
    <div *ngIf="addingNewActivityBlock" class="row">
        <app-date-range-picker [startDate]="newActivityBlock.dateStart"
            [endDate]="newActivityBlock.dateEnd" (rangeToEmit)="getRange($event)">
        </app-date-range-picker>
        <button class="btn btn-success btn-circle btn-sm mx-2"
            (click)="onSubmitActivityBlock(null); addingNewActivityBlock = false"
            matTooltip="{{ 'save' | translate }}" tooltipClass="tooltip-success">
            <i class="fas fa-check"></i>
        </button>
        <button class="btn btn-danger btn-circle btn-sm mr-2" (click)="addingNewActivityBlock = false"
            matTooltip="{{ 'cancel' | translate }}" tooltipClass="tooltip-danger">
            <i class="fas fa-times"></i>
        </button>
    </div>

    <div *ngIf="locationActivityBlocks.length == 0">{{ 'none' | translate}}</div>

    <div class="mt-2" *ngFor="let activityBlock of locationActivityBlocks; let i = index">
        <div *ngIf="changedActivityBlock.id != activityBlock.id">
            {{ activityBlock.dateStart | date: 'dd/MM/yyyy' }} -
            {{ activityBlock.dateEnd | date: 'dd/MM/yyyy' }}
            <button class="btn btn-info btn-circle btn-sm mr-2"
                (click)="editActivityBlock(activityBlock); addingNewActivityBlock = false"
                matTooltip="{{ 'edit' | translate }}" tooltipClass="tooltip-info">
                <i class="fas fa-edit"></i>
            </button>
            <button class="btn btn-danger btn-circle btn-sm mr-2" (click)="deleteActivityBlock(activityBlock)"
                matTooltip="{{ 'delete' | translate }}" tooltipClass="tooltip-danger">
                <i class="fas fa-trash"></i>
            </button>
        </div>
        <div *ngIf="changedActivityBlock.id == activityBlock.id" class="row">
            <app-date-range-picker [startDate]="activityBlock.dateStart"
                [endDate]="activityBlock.dateEnd" (rangeToEmit)="getRange($event)">
            </app-date-range-picker>
            <button class="btn btn-success btn-circle btn-sm mx-2" (click)="onSubmitActivityBlock(activityBlock)"
                matTooltip="{{ 'save' | translate }}" tooltipClass="tooltip-success">
                <i class="fas fa-check"></i>
            </button>
            <button class="btn btn-danger btn-circle btn-sm mr-2" (click)="editActivityBlock(newActivityBlock)"
                matTooltip="{{ 'cancel' | translate }}" tooltipClass="tooltip-danger">
                <i class="fas fa-times"></i>
            </button>
        </div>
    </div>
</div>
