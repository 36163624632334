<div #pagetop class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 *ngIf="!isEditForm" class="col-9 m-0 font-weight-bold text-primary">{{ 'add-device' | translate }}</h5>
            <h5 *ngIf="isEditForm" class="col-9 m-0 font-weight-bold text-primary">{{ 'edit-device' | translate }}</h5>
            <div class="col-3 text-right">
                <mat-slide-toggle color="primary" [checked]="advancedMode"
                    (change)="advancedMode = !advancedMode">{{ 'advanced-mode' | translate }}</mat-slide-toggle>
            </div>
        </div>
    </div>
    <div *ngIf="device" class="card-body">
        <form #form="ngForm" autocomplete="off">
            <div class="form-group mt-2">
                <div class="input-group">
                    *<div class="font-weight-bold">{{ 'device-name' | translate }}</div>
                </div>
                <div class="input-group">
                    <input name="deviceName" #deviceName="ngModel" [(ngModel)]="device.deviceName"
                        class="form-control" required>
                </div>
                <div *ngIf="deviceName.invalid && (deviceName.dirty || deviceName.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="deviceName.errors.required">{{ 'device-name-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    *<div class="font-weight-bold">{{ 'device-type' | translate }}</div>
                </div>
                <mat-form-field class="ml-2">
                    <mat-select name="deviceType" #deviceType="ngModel" [(ngModel)]="device.deviceType" required>
                        <mat-option [value]="DeviceType.CallConsole">{{ 'call-console' | translate }}</mat-option>
                        <mat-option [value]="DeviceType.TicketDispenser">{{ 'ticket-dispenser' | translate }}</mat-option>
                        <mat-option [value]="DeviceType.MainDisplay">{{ 'main-display' | translate }}</mat-option>
                        <!-- TODO: uncomment when migration of calling display is complete -->
                        <!-- <mat-option [value]="DeviceType.CallingDisplay">{{ 'calling-display' | translate }}</mat-option> -->
                        <mat-option [value]="DeviceType.DeskDisplay">{{ 'desk-display' | translate }}</mat-option>
                        <mat-option [value]="DeviceType.TicketsForHoursDispenser">{{ 'ticket-for-hours-dispenser' | translate }}</mat-option>

                        <mat-option *ngIf="locationService.getLocationConstraints().isClientSurveyEnabled"
                            [value]="DeviceType.CustomerSurveyDisplay">
                            {{ 'customer-survey-display' | translate }}
                        </mat-option>
                        <mat-option *ngIf="!locationService.getLocationConstraints().isClientSurveyEnabled"
                            disabled matTooltipPosition="right" matTooltipClass="tooltip-orange"
                            matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                            {{ 'customer-survey-display' | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <div *ngIf="deviceType.invalid && (deviceType.dirty || deviceType.touched)" class="text-danger">
                    <div *ngIf="deviceType.errors.required">{{ 'device-type-required' | translate }}</div>
                </div>
            </div>

            <div *ngIf="device.deviceType && advancedMode">
                <div class="form-group">
                    {{ 'displayed-category-name' | translate }}
                    <mat-form-field class="ml-2">
                        <mat-select name="categoryDisplayNameType" #categoryDisplayNameType="ngModel"
                            [(ngModel)]="device.categoryDisplayNameType">
                            <mat-option [value]="CategoryDisplayNameType.FullName">{{ 'full-name' | translate }}</mat-option>
                            <mat-option [value]="CategoryDisplayNameType.ShortName">{{ 'short-name' | translate }}</mat-option>
                            <mat-option [value]="CategoryDisplayNameType.Tag">{{ 'tag' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="form-group">
                    {{ 'device-title' | translate }}
                    <mat-slide-toggle color="primary" [checked]="deviceTitle"
                        (change)="deviceTitle = !deviceTitle"></mat-slide-toggle>
                    <div *ngIf="!deviceTitle" class="input-group">
                        <label>{{ 'default-location-name' | translate }}</label>
                    </div>
                    <div *ngIf="deviceTitle" class="input-group">
                        <input name="title" #title="ngModel" [(ngModel)]="device.title"
                            class="form-control">
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="advancedMode">

                <!-- Due to Task: 5528
                <div class="font-weight-bold">{{ 'device-activity' | translate }}</div>
                <app-weekly-activity [unblockingTimeDaysValue]="device.deviceActivityTimes"
                    [nightWork]="nightWork">
                </app-weekly-activity>
            -->
            </div>

            <div *ngIf="device.deviceType == DeviceType.TicketDispenser">
                <app-ticket-dispenser *ngIf="categories" [device]="device" [advancedMode]="advancedMode"
                    [categories]="categories">
                </app-ticket-dispenser>
            </div>

            <div *ngIf="device.deviceType == DeviceType.TicketsForHoursDispenser">
                <app-ticket-for-hours-dispenser *ngIf="categories" [device]="device" [advancedMode]="advancedMode"
                    [categories]="categories">
                </app-ticket-for-hours-dispenser>
            </div>

            <div *ngIf="device.deviceType == DeviceType.MainDisplay">
                <app-main-display *ngIf="desks && categories" [device]="device" [desks]="desks"
                    [categories]="categories" (filePathToEmit)="getDeskIcons($event)">
                </app-main-display>
            </div>

            <div *ngIf="device.deviceType == DeviceType.DeskDisplay">
                <app-desk-display *ngIf="desks" [desks]="desks" [device]="device">
                </app-desk-display>
            </div>

            <div *ngIf="device.deviceType == DeviceType.CustomerSurveyDisplay">
                <app-customer-survey-display *ngIf="desks" [device]="device" [desks]="desks">
                </app-customer-survey-display>
            </div>

            <div *ngIf="device.deviceType == DeviceType.CallingDisplay">
                <app-calling-display *ngIf="desks" [device]="device" [desks]="desks">
                </app-calling-display>
            </div>

            <div *ngIf="activationCode" class="font-weight-bold">
                {{ 'activation-code' | translate }}:
                <div class="btn btn-activation btn-md mx-1 font-weight-bold">
                    {{ activationCode }}
                </div>
            </div>

            <div class="form-group row">
                <button class="btn btn-danger m-1" routerLink="/devices">{{ 'back' | translate }}</button>
                <button class="btn btn-primary m-1" type="submit" *ngIf="!activationCode"
                    [disabled]="formSubmitted || form.invalid" (click)="onSubmit(categoryMultiCtrl?.value)">
                <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                    aria-hidden="true"></span>{{ 'save-device' | translate }}
                </button>
                <button *ngIf="isEditForm && !activationCode" class="btn btn-info m-1" type="submit"
                    [disabled]="formSubmitted || form.invalid" (click)="isEditForm = false;
                    pagetop.scrollIntoView({behavior: 'smooth'})">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>{{ 'add-new-device-based-on-existing' | translate }}
                </button>
            </div>
            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>
