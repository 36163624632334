<div *ngIf="!isConfirmVisible && ticket.id!=-1" class="input-group">
    <!--<select class="custom-select" [(ngModel)]="selectedCategory">
        <option *ngFor="let category of categories" [ngValue]="category">
            <b>{{ category.categoryTag }}</b>
            <span class="element-text">{{ category.fullName }}</span>
        </option>
    </select>-->
    <textarea maxlength="50" class="form-control" aria-label="With textarea" name="Note" [(ngModel)]="TicketNoteVm.Note"></textarea>
    <div class="input-group-append">
        <button class="btn btn-primary"
        (click)="toggleConfirm()">{{ 'console.note-ticket' | translate }}</button>
    </div>
</div>

<div *ngIf="isConfirmVisible && ticket.id!=-1">
    <h5 class="title">{{ 'console.note-ticket-confirm' | translate}}</h5>
    <div class="input-group-append">
        <button class="btn btn-secondary mr-1" (click)="toggleConfirm()">{{ 'cancel' | translate }}</button>
        <button class="btn btn-success" (click)="addNote(ticket.id)">{{ 'submit' | translate }}</button>
    </div>
</div>