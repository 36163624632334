import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-redirect-ticket-button',
  templateUrl: './redirect-ticket-button.component.html',
  styleUrls: ['./redirect-ticket-button.component.css',  './../base-button/base-button.component.css']
})
export class RedirectTicketButtonComponent  implements OnInit {
  @Input() public tooltipTitle: string;
	@ViewChild('button') public button: ElementRef;
  @ViewChild('icon') public icon: ElementRef;
  isEnabled: boolean;
  isBlocked: boolean;
  isLocked: boolean;
  serveETicket: boolean;

  constructor(private consoleService: QcloudConsoleService) {
    this.consoleService.ticket.subscribe((val) => {
      if (val && val.id != 0 && val.id != -1) {
        this.isEnabled = true;
      }
      else {
        this.isEnabled = false;
      }
    })
    this.consoleService.isBlocked.subscribe((val) => {
      this.isBlocked = val;
    });
    this.consoleService.isLocked.subscribe((val) => {
      this.isLocked = val;
    });
  }

  ngOnInit(): void {
    this.serveETicket = this.consoleService.user.hasPermissionToServeETickets;
  }

  public redirectTicket() {
    if (this.isEnabled) {
      if (!this.serveETicket) {
        this.consoleService.openRedirectModal();
      } else {
        this.consoleService.redirectETicket();
      }
    }
  }
}
