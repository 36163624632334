import { CategoryPriority } from '../category/category-priority.enum';
import { Category } from '../category/category.model';

export class DeskCategory {
    public categoryId: number;
    public categoryPriority: CategoryPriority
    public name: string;
    public tag: string;

    constructor(category: Category) {
        this.name = category.fullName;
        this.tag = category.categoryTag;
        this.categoryId = category.id;
        this.categoryPriority = CategoryPriority.Mid;
    }
}
