<div #pagetop class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="m-0 font-weight-bold text-primary">{{ 'statistics-tickets-history' | translate }}</h5>
    </div>
    <div class="card-body">
    <div *ngIf="categoryMultiCtrl">
        <form #form="ngForm" autocomplete="off">
            
            <div class="row col-4">
                <app-single-picker *ngIf="categoryMultiCtrl" [elementMultiCtrl]="categoryMultiCtrl"
                [elements]="categories" [title]="selectCategory" [noSelectedElements]="noSelectedElements"
                [displayElements]="true" [placeholder]="placeholder" [nomatch]="nomatch">
                </app-single-picker>
            </div>

            <div class="row col-4">
                <div class="input-group">
                    *<div class="font-weight-bold">{{ 'ticket-number' | translate }}</div>
                </div>
                <div class="input-group">
                    <input name="ticketNumber" type="number" min="1" #ticketNumber="ngModel" [(ngModel)]="ticketParams.ticketNumber"
                        class="form-control" maxlength="4" required>
                </div>                    
            </div>

            <div class="row col-4">
                <mat-form-field>
                    <mat-label>{{'select-date' | translate}}</mat-label>
                    <input matInput [matDatepicker]="picker" [formControl]="dateCtrl" required>
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker touchUi="true" #picker></mat-datepicker>
                </mat-form-field>
            </div>

            <div class="form-group">
                <app-submit-button [disabled]="form.invalid" (click)="getTicketHistory()"
                    type="submit" [text]="'get-ticket-history' | translate">
                </app-submit-button>
            </div>

            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>

    <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>#</th>
                    <th>{{ 'ticket-number' | translate }}</th>
                    <th>{{ 'event-time' | translate }}</th>
                    <th>{{ 'event-type' | translate }}</th>
                    <th>{{ 'informations' | translate }}</th>
                    <th>{{ 'ticket-note' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let singleTicketEvent of ticketHistoryData; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ singleTicketEvent.ticketNumber }}</td>
                    <td>{{ singleTicketEvent.eventTime }}</td>
                    <td>{{ singleTicketEvent.eventType }}</td>
                    <td>{{ singleTicketEvent.info }}</td>
                    <td>{{ singleTicketEvent.note }}</td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
</div>


