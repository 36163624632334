import { Component, OnInit, OnDestroy } from '@angular/core';
import { DeviceService } from 'projects/qcloud-rest-client/src/lib/device.service';
import { Device } from 'projects/qcloud-models/device/device.model';
import { DeviceTable } from 'projects/qcloud-models/device/device-table.model';
import { DeviceType } from 'projects/qcloud-models/device/device-type.enum';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { QcloudStompClientService } from 'projects/qcloud-stomp-client/src/lib/qcloud-stomp-client.service';
import { Subscription, timer, Observable } from 'rxjs';
import { LayoutType } from 'projects/qcloud-models/device/layout-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LicenseService } from 'projects/qcloud-rest-client/src/lib/license.service';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.css']
})
export class DevicesComponent implements OnInit, OnDestroy {

  public devices: Device[];
  selectedDeviceGuid: string;
  DeviceType = DeviceType;
  LayoutType = LayoutType;
  public deviceTable: DeviceTable[];
  private heartbeatTimerSub: Subscription;
  private timer: Observable<number>;
  private stompHeartbeatSubscription: Subscription;

  constructor(private deviceService: DeviceService, private deskService: DeskService,
    private translate: TranslateService, private toastr: ToastrService, private router: Router,
    private qcloudStompClientService: QcloudStompClientService, private licenseService: LicenseService,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.getDevices();
  }

  getDevices() {
    this.deviceService.getDevices((data) => {
      this.devices = data;
      this.deviceTable = this.devices.map(device => new DeviceTable(device, this.deskService));
      this.devices.forEach(device => {
        device.isAliveLastTime = new Date();
      });
      this.startHeartbeatListening();
    },
      () => {

      });
    this.timer = timer(7500, 7000);
    var context = this;
    this.heartbeatTimerSub = this.timer.subscribe(t => {
      this.devices.forEach(element => {
        let lastTime = new Date(element.isAliveLastTime);
        let seconds = lastTime.getSeconds();
        lastTime.setSeconds(seconds + 7);
        if (lastTime < new Date()) {
          var item = context.getItemFromTableByName(element.deviceName);
          item.isAlive = false;
        }
      });
      this.refreshTable();
    });
  }

  private startHeartbeatListening() {
    var context = this;
    let locationId = this.authService.getLocationId();
    let systemId = this.authService.getSystemId();
    this.stompHeartbeatSubscription = this.qcloudStompClientService.subscribe(`/exchange/heartbeat/${systemId}.${locationId}`, (payload) => {
      this.devices.forEach(element => {
        if (element.guid == payload) {
          var item = context.getItemFromTableByName(element.deviceName);
          item.isAlive = true;
          element.isAliveLastTime = new Date();
        }
      });
      this.refreshTable();
    });
  }

  private refreshTable() {
    this.deviceTable = Object.assign([], this.deviceTable);
  }

  private getItemFromTableByName(deviceName: string): DeviceTable {
    let searchTarget = null;
    this.deviceTable.forEach(item => {
      if (item.deviceName == deviceName) {
        searchTarget = item;
      }
    })
    return searchTarget;
  }

  ngOnDestroy(): void {
    this.stopHeartBeat();
  }

  stopHeartBeat() {
    this.stompHeartbeatSubscription.unsubscribe();
    this.heartbeatTimerSub.unsubscribe();
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteDevice(this.selectedDeviceGuid);
    }
  }

  deleteDevice(deviceGuid: string) {
    this.stopHeartBeat();
    this.deviceService.deleteDevice(deviceGuid).subscribe(
      res => {
        this.getDevices();
      },
      err => {
        this.translate.get('delete-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  restartItem(deviceGuid: string) {
    this.deviceService.restartDevice(deviceGuid).subscribe(
      res => {
        this.translate.get('reset-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.translate.get('reset-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  restartAll() {
    this.deviceService.restartAll().subscribe(
      res => {
        this.translate.get('reset-all-success').subscribe((res: string) => {
          this.toastr.success(res);
        });
      },
      err => {
        this.translate.get('reset-all-error').subscribe((res: string) => {
          let error = res;
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  goToAddDevice() {
    this.licenseService.getDevicesLeft().subscribe(
      devicesLeft => {
        if (devicesLeft > 0) {
          this.router.navigateByUrl('/device');
        } else {
          this.translate.get('licence-exceeded').subscribe((error: string) => {
            this.toastr.error(error);
          });
        }
      },
      err => {}
    );
  }
}
