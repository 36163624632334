import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ConsoleModal } from 'projects/qcloud-models/console/console-modal';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-slim-interaction-card',
  templateUrl: './slim-interaction-card.component.html',
  styleUrls: ['./slim-interaction-card.component.css']
})
export class SlimInteractionCardComponent implements OnInit {

  redirectModal: ConsoleModal;
  redirectToCategoryModal: ConsoleModal;
  redirectToDeskModal: ConsoleModal;
  redirectToForHourModal: ConsoleModal;
  cancelledTicketsModal: ConsoleModal;
  pauseModal: ConsoleModal;
  pausedTicketsModal: ConsoleModal;
  generateTicketModal: ConsoleModal;
  noteTicketModal: ConsoleModal;
  manualCallTicketModal: ConsoleModal;
  manualShowTicketModal: ConsoleModal;
  blockedCategoriesModal: ConsoleModal;
  servedCategoriesModal: ConsoleModal;
  logoutModal: ConsoleModal;

  title: string;
  cardMainTitle: string = "console.interaction-card";

  @Output() hideInteractionModel = new EventEmitter<boolean>();

  constructor(public consoleService: QcloudConsoleService) {
    this.redirectModal = new ConsoleModal(false, "");
    this.redirectToCategoryModal = new ConsoleModal(false, "");
    this.redirectToDeskModal = new ConsoleModal(false, "");
    this.redirectToForHourModal = new ConsoleModal(false, "");
    this.cancelledTicketsModal = new ConsoleModal(false, "");
    this.pauseModal = new ConsoleModal(false, "");
    this.pausedTicketsModal = new ConsoleModal(false, "");
    this.generateTicketModal = new ConsoleModal(false, "");
    this.noteTicketModal = new ConsoleModal(false, "");
    this.manualCallTicketModal = new ConsoleModal(false, "");
    this.manualShowTicketModal = new ConsoleModal(false, "");
    this.blockedCategoriesModal = new ConsoleModal(false, "");
    this.servedCategoriesModal = new ConsoleModal(false, "");
    this.logoutModal = new ConsoleModal(false, "");
    consoleService.redirectModal.subscribe(
      (val: ConsoleModal) => {
        this.redirectModal = val;
        this.handleModal(this.redirectModal);
      }
    );
    consoleService.redirectToCategoryModal.subscribe(
      (val: ConsoleModal) => {
        this.redirectToCategoryModal = val;
        this.handleModal(this.redirectToCategoryModal);
      }
    );
    consoleService.redirectToForHourModal.subscribe(
      (val: ConsoleModal) => {
        this.redirectToForHourModal = val;
        this.handleModal(this.redirectToForHourModal);
      }
    );
    consoleService.redirectToDeskModal.subscribe(
      (val: ConsoleModal) => {
        this.redirectToDeskModal = val;
        this.handleModal(this.redirectToDeskModal);
      }
    );
    consoleService.cancelledTicketsModal.subscribe(
      (val: ConsoleModal) => {
        this.cancelledTicketsModal = val;
        this.handleModal(this.cancelledTicketsModal);
      }
    );
    consoleService.pauseModal.subscribe(
      (val: ConsoleModal) => {
        this.pauseModal = val;
        this.handleModal(this.pauseModal);
      }
    );
    consoleService.pausedTicketsModal.subscribe(
      (val: ConsoleModal) => {
        this.pausedTicketsModal = val;
        this.handleModal(this.pausedTicketsModal);
      }
    );
    consoleService.generateTicketModal.subscribe(
      (val: ConsoleModal) => {
        this.generateTicketModal = val;
        this.handleModal(this.generateTicketModal);
      }
    );
    consoleService.noteTicketModal.subscribe(
      (val: ConsoleModal) => {
        this.noteTicketModal = val;
        this.handleModal(this.noteTicketModal);
      }
    );
    consoleService.manualCallTicketModal.subscribe(
      (val: ConsoleModal) => {
        this.manualCallTicketModal = val;
        this.handleModal(this.manualCallTicketModal);
      }
    );
    consoleService.manualShowTicketModal.subscribe(
      (val: ConsoleModal) => {
        this.manualShowTicketModal = val;
        this.handleModal(this.manualShowTicketModal);
      }
    );
    consoleService.blockedCategoriesModal.subscribe(
      (val: ConsoleModal) => {
        this.blockedCategoriesModal = val;
        this.handleModal(this.blockedCategoriesModal);
      }
    );
    consoleService.servedCategoriesModal.subscribe(
      (val: ConsoleModal) => {
        this.servedCategoriesModal = val;
        this.handleModal(this.servedCategoriesModal);
      }
    );
    consoleService.logoutModal.subscribe(
      (val: ConsoleModal) => {
        this.logoutModal = val;
        this.handleModal(this.logoutModal);
      }
    );
    this.title = this.cardMainTitle;
  }

  handleModal(consoleModal: ConsoleModal) {
    if (consoleModal.isOpen) {
      this.title = consoleModal.title;
    } else {
      this.title = this.cardMainTitle;
    }
  }

  ngOnInit(): void {
  }

  hideInteractionModelCard()
  {
    this.hideInteractionModel.emit(false);
  }
}