import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ChangeContext, Options, PointerType } from 'ngx-slider-v2';
import { ActivityTime } from 'projects/qcloud-models/activity-time';
import { TimeConversionService } from '../time-conversion.service';

@Component({
  selector: 'app-weekly-activity',
  templateUrl: './weekly-activity.component.html',
  styleUrls: ['./weekly-activity.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WeeklyActivityComponent implements OnInit {

  @Input() unblockingTimeDaysValue: ActivityTime[];
  @Input() nightWork: Array<boolean>;
  @Input() withOffices: any;
  minValues: Array<number> = new Array(7);
  maxValues: Array<number> = new Array(7);
  copiedNightWork: boolean;
  copiedUnblockingTime: any;
  copiedBlockingTime: any;
  copiedIs24Hours: boolean;
  copiedIsButtonAlwaysVisible: boolean;
  options: Options = {
    floor: 0,
    ceil: 1440,
    step: 10,
    enforceStep: false,
    translate: (value: number): string => {
      return this.timeConverter.minutesToTime(value);
    },
    combineLabels: (minValue: string, maxValue: string): string => {
      return minValue + ' - ' + maxValue;
    }
  };
  dayName = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

  constructor(private timeConverter: TimeConversionService) { }

  ngOnInit(): void {
    for (let i = 0; i < this.minValues.length; i++) {
      this.minValues[i] = this.timeConverter.timeToMinutes(this.unblockingTimeDaysValue[i].unblockingTime);
      this.maxValues[i] = this.timeConverter.timeToMinutes(this.unblockingTimeDaysValue[i].blockingTime);
    }
  }

  onUserChange(changeContext: ChangeContext, day: number): void {
    if (changeContext.pointerType === PointerType.Min) {
      this.setUnblockingTime(day, changeContext.value);
    } else {
      this.setBlockingTime(day, changeContext.highValue);
    }
  }

  updateSlider(day: number) {
    this.minValues[day] = this.timeConverter.timeToMinutes(this.unblockingTimeDaysValue[day].unblockingTime);
    this.maxValues[day] = this.timeConverter.timeToMinutes(this.unblockingTimeDaysValue[day].blockingTime);
  }

  setUnblockingTime(day: number, value: number) {
    this.unblockingTimeDaysValue[day].unblockingTime = this.timeConverter.minutesToTime(value);
  }

  setBlockingTime(day: number, value: number) {
    this.unblockingTimeDaysValue[day].blockingTime = this.timeConverter.minutesToTime(value);
  }

  copy(day: number) {
    this.copiedBlockingTime = this.unblockingTimeDaysValue[day].blockingTime;
    this.copiedUnblockingTime = this.unblockingTimeDaysValue[day].unblockingTime;
    this.copiedIs24Hours = this.unblockingTimeDaysValue[day].is24Hours;
    this.copiedIsButtonAlwaysVisible = this.unblockingTimeDaysValue[day].isButtonAlwaysVisible;
    this.copiedNightWork = this.nightWork[day];
  }

  paste(day: number) {
    if(this.copiedUnblockingTime) {
      this.unblockingTimeDaysValue[day].blockingTime = this.copiedBlockingTime;
      this.unblockingTimeDaysValue[day].unblockingTime = this.copiedUnblockingTime;
      this.unblockingTimeDaysValue[day].is24Hours = this.copiedIs24Hours;
      this.unblockingTimeDaysValue[day].isButtonAlwaysVisible = this.copiedIsButtonAlwaysVisible;
      this.updateSlider(day);
      this.nightWork[day] = this.copiedNightWork;
    }
  }

}
