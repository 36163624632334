<div *ngIf="locationService.getLocationConstraints().areEnabledTicketsReservations">
    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <h5 class="m-0 font-weight-bold text-primary">{{ 'reservation-configuration' | translate }}</h5>
        </div>
        <div *ngIf="categories && reservationConfiguration" class="card-body">
            <app-served-categories *ngIf="categoryMultiCtrl" [categories]="categories"
                [categoryMultiCtrl]="categoryMultiCtrl" [noSelectedCategories]="noSelectedCategories"
                [displayCategories]="true">
            </app-served-categories>

            <div class="form-group">
                <mat-label> {{ 'last-reservation-day' | translate }}</mat-label>
                <input name="reservationConfiguration.reservationsEndTime"
                    [(ngModel)]="reservationConfiguration.reservationsEndTime" class="form-control" type="number"
                    required>
            </div>

            <div class="form-group">
                <mat-label> {{ 'generating-reservations-ahead-of-time' | translate }}</mat-label>
                <input name="reservationConfiguration.beforeReservationMins"
                    [(ngModel)]="reservationConfiguration.beforeReservationMins" class="form-control" type="number"
                    required>
            </div>

            <div class="form-group">
                <mat-label> {{ 'late-reservation' | translate }}</mat-label>
                <input name="reservationConfiguration.afterReservationMins"
                    [(ngModel)]="reservationConfiguration.afterReservationMins" class="form-control" type="number"
                    required>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox color="primary" [(ngModel)]="reservationConfiguration.allowSameDayReservation">
                        {{ 'allow-same-day-reservation' | translate }}
                    </mat-checkbox>
                </div>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox color="primary" [(ngModel)]="reservationConfiguration.sendReminder">
                        {{ 'send-reminder' | translate }}
                    </mat-checkbox>
                </div>

                <div class="item-group" *ngIf="reservationConfiguration.sendReminder">
                    <mat-label> {{ 'additional-reminder-text' | translate }}</mat-label>
                    <textarea name="reservationConfiguration.additionalReminderText"
                        [(ngModel)]="reservationConfiguration.additionalReminderText" class="form-control"></textarea>
                </div>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox color="primary" [(ngModel)]="reservationConfiguration.sendReservationEmail">
                        {{ 'send-mail' | translate }}
                    </mat-checkbox>
                </div>

                <div class="item-group" *ngIf="reservationConfiguration.sendReservationEmail">
                    <mat-label> {{ 'additional-email-text' | translate }}</mat-label>
                    <textarea name="reservationConfiguration.additionalEmailText"
                        [(ngModel)]="reservationConfiguration.additionalEmailText" class="form-control"></textarea>
                </div>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox color="primary" [(ngModel)]="reservationConfiguration.sendQrCode">
                        {{ 'send-qr-code' | translate }}
                    </mat-checkbox>
                </div>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox *ngIf="isSmsEnabled" color="primary"
                        [(ngModel)]="reservationConfiguration.sendReservationSms">
                        {{ 'send-sms' | translate }}
                    </mat-checkbox>

                    <mat-checkbox *ngIf="!isSmsEnabled" disabled matTooltipPosition="right"
                        matTooltipClass="tooltip-orange"
                        matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                        {{ 'send-sms' | translate }}
                    </mat-checkbox>
                </div>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox color="primary" [(ngModel)]="reservationConfiguration.isRecaptcha">
                        {{ 'is-recaptcha-active' | translate }}</mat-checkbox>
                </div>
            </div>
            <div class="form-group" *ngIf="reservationConfiguration.isRecaptcha">
                <mat-label>{{ 'recaptcha-public-key' | translate }}</mat-label>
                <input [(ngModel)]="reservationConfiguration.recaptchaPublicKey" class="form-control">
            </div>
            <div class="form-group" *ngIf="reservationConfiguration.isRecaptcha">
                <mat-label>{{ 'recaptcha-private-key' | translate }}</mat-label>
                <input [(ngModel)]="reservationConfiguration.recaptchaPrivateKey" class="form-control">
            </div>

            <div class="form-group">
                <mat-label> {{ 'reservation-address' | translate }}</mat-label>
                <input name="reservationConfiguration.reservationAddress"
                    [(ngModel)]="reservationConfiguration.reservationAddress" class="form-control" required>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox color="primary" [(ngModel)]="reservationConfiguration.twoStepVerification">
                        {{ 'two-step-verification' | translate }}</mat-checkbox>
                </div>

                <div class="item-group" *ngIf="reservationConfiguration.twoStepVerification">
                    <mat-label>{{ 'remove-unconfirmed-reservation' | translate }}</mat-label>
                    <input [(ngModel)]="reservationConfiguration.removeUnconfirmedMins" class="form-control">
                </div>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox color="primary" [(ngModel)]="reservationConfiguration.supportsWcag">
                        {{ 'wcag-support' | translate }}
                    </mat-checkbox>
                </div>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox color="primary" [(ngModel)]="reservationConfiguration.alwaysActive">
                        {{ 'reservations-always-active' | translate }}
                    </mat-checkbox>
                </div>

                <div class="item-group" *ngIf="!reservationConfiguration.alwaysActive">
                    <mat-label>{{ 'reservation-start-hour' | translate }}</mat-label>
                    <input [(ngModel)]="reservationConfiguration.startHour" class="form-control">
                </div>

                <div class="item-group" *ngIf="!reservationConfiguration.alwaysActive">
                    <mat-label>{{ 'reservation-end-hour' | translate }}</mat-label>
                    <input [(ngModel)]="reservationConfiguration.endHour" class="form-control">
                </div>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox color="primary" [(ngModel)]="reservationConfiguration.supportsCsvImport">
                        {{ 'csv-import-support' | translate }}
                    </mat-checkbox>
                </div>
                <div class="alert alert-warning" role="alert" *ngIf="reservationConfiguration.supportsCsvImport">
                    {{ 'csv-overrite-warning' | translate }}
                </div>
            </div>
            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox color="primary" [(ngModel)]="reservationConfiguration.supportsCsvExport">
                        {{ 'csv-export-support' | translate }}
                    </mat-checkbox>
                </div>
            </div>
            <div class="form-group">
                <mat-label> {{ 'reservation-terms-title' | translate }}</mat-label>
                <input name="reservationConfiguration.termsTitle" [(ngModel)]="reservationConfiguration.termsTitle"
                    class="form-control" required>
            </div>
            <div class="form-group editor" [formGroup]="form">
                <mat-label> {{ 'reservation-terms' | translate }}</mat-label>
                <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                <ngx-editor [editor]="editor" formControlName="editorContent">
                </ngx-editor>
            </div>

            <div class="form-group row">
                <button class="btn btn-primary m-1" type="submit" (click)="saveConfiguration()"
                    [disabled]="formSubmitted">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span> {{ 'save-changes' | translate }}
                </button>
            </div>
        </div>
    </div>
    <app-reservation-vacation *ngIf="reservationCategories" [reservationCategories]="reservationCategories">
    </app-reservation-vacation>

    <!-- <app-reservation-group-by-categories *ngIf="categoryMultiCtrl">
    </app-reservation-group-by-categories> -->

    <app-reservation-schedule *ngIf="reservationCategories && reservationCategoryGroups"
        [reservationCategories]="reservationCategories" [reservationCategoryGroups]="reservationCategoryGroups">
    </app-reservation-schedule>

    <app-reservation-questionaire *ngIf="questions && reservationCategories" [questions]="questions"
        [reservationCategories]="reservationCategories" (changedQuestions)="getReservationQuestionsAndLimits()">
    </app-reservation-questionaire>

    <app-reservation-limits *ngIf="reservationLimits && questions" [reservationLimits]="reservationLimits"
        [questions]="questions"></app-reservation-limits>
</div>

<app-licence-info *ngIf="!locationService.getLocationConstraints().areEnabledTicketsReservations"
    [title]="'reservation-configuration'">
</app-licence-info>