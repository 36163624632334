<div class="login-container mt-4">
    <div class="justify-content-center">
        <div class="card o-hidden border-0 shadow-lg">
            <div class="card-body p-0">
                <div class="p-5">
                    <div class="text-center">
                        <h1 class="h4 text-gray-900 mb-4">{{ 'console.log-in-to-console' | translate }}!</h1>
                    </div>

                    <div class="form-group">
                        <select [(ngModel)]="choosenDesk" class="form-control">
                            <option *ngFor="let desk of deskList" [ngValue]="desk">
                                {{ desk.fullName }}
                            </option>
                        </select>
                    </div>

                    <div class="form-group">
                        <select [(ngModel)]="username" class="form-control" [disabled]="isLoginByWEB">
                            <option *ngFor="let user of userList" [ngValue]="user.userName">
                                {{ user.userName }}
                            </option>
                        </select>
                    </div>
                    <!--
                    <div class="form-group">
                        <input class="form-control" [(ngModel)]="username" type="text" />
                    </div>
                    -->

                    <div class="form-group">
                        <input class="form-control" [(ngModel)]="password" type="password" [disabled]="isLoginByWEB"/>
                    </div>
                    <a (click)="loginToConsole()" class="btn btn-primary btn-user btn-block btn-login">
                        {{ 'log-in' | translate }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>