import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { GetAvailableTimesParameter } from 'projects/qcloud-models/reservation/get-available-times-parameter';
import { ReservationLimit } from 'projects/qcloud-models/reservation/reservation-limit';
import { ReservationModuleConfiguration } from 'projects/qcloud-models/reservation/reservation-module-configuration';
import { ReservationMonthCapacity } from 'projects/qcloud-models/reservation/reservation-month-capacity';
import { ReservationQuestion } from 'projects/qcloud-models/reservation/reservation-questions';
import { ReservationSchedule } from 'projects/qcloud-models/reservation/reservation-schedule';
import { TicketReservation } from 'projects/qcloud-models/reservation/ticket-reservation';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { TicketForHourInfo } from 'projects/qcloud-device/src/app/devices/desk-display/components/desk-ticket-for-hour-queue-display/ticket-for-hour-info';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})
export class ReservationService {

  appBase: string;

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  constructor(private http: HttpClient, private authService: AuthService) { }

  getMonthCapacityForCategory(availableTimes: GetAvailableTimesParameter, callback: (data: ReservationMonthCapacity) => any, onError: () => any) {
    this.http.post(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/getmonthcapacity`, availableTimes)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getReservationListForNextDays(categoryId: number, day: string, days: number, callback: (data: TicketReservation[]) => any, onError: () => any) {
    let payload = {
      "Date": day,
      "CategoryId": categoryId,
      "ReservationDays": days
    }

    this.http.post(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/getlist/`, payload)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
    }

  getTicketsReservations(startDay: Date, endDay: Date, callback: (data: TicketReservation[]) => any, onError: () => any) {
    var httpheaders = new HttpHeaders();
    httpheaders = httpheaders.set('startDay', startDay.toUTCString());
    httpheaders = httpheaders.set('endDay', endDay.toUTCString());

    this.http.get(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/getall/`, { headers: httpheaders } )
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getTicketsReservationsForDisplayCategoryDesk(startDay: Date, endDay: Date, deskId: number, limit : number, callback: (data: TicketForHourInfo[]) => any, onError: () => any) {
    var httpheaders = new HttpHeaders();
    httpheaders = httpheaders.set('deskId', deskId.toString());
    httpheaders = httpheaders.set('startDay', startDay.toUTCString());
    httpheaders = httpheaders.set('endDay', endDay.toUTCString());
    httpheaders = httpheaders.set('quantityLimitResult', limit.toString());

    this.http.get(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/ticket-for-display/`, { headers: httpheaders } )
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  deleteReservation(id: number, callback: () => any, onError: () => any) {
    this.http.delete(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/${id}`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  createReservation(reservation: TicketReservation) {
    return this.http.post(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/`, reservation);
  }

  updateReservation(reservation: TicketReservation) {
    return this.http.put(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/`, reservation);
  }

  setReservationConfiguration(configuration: ReservationModuleConfiguration, callback: () => any, onError: () => any) {
    this.http.put(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/configuration`, configuration)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getReservationConfiguration(callback: (data: ReservationModuleConfiguration) => any, onError: () => any) {
    this.http.get(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/configuration`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  addReservationCategorySchedule(reservation: ReservationSchedule, categoryId: number, callback: () => any, onError: () => any) {
    this.http.post(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/schedule/${categoryId}`, reservation)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  addReservationMultipleCategorySchedule(reservation: ReservationSchedule, compositionId: number, callback: () => any, onError: () => any) {
    this.http.post(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/schedule/composition/${compositionId}`, reservation)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  updateReservationMultipleCategorySchedule(reservation: ReservationSchedule, compositionId: number, callback: () => any, onError: () => any) {
    this.http.post(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/schedule/composition/update/${compositionId}`, reservation)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  updateReservationCategorySchedule(schedule: ReservationSchedule, categoryId: number, callback: () => any, onError: () => any) {
    this.http.put(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/schedule/${categoryId}/${schedule.id}`, schedule)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  deleteReservationCategorySchedule(scheduleId: number, categoryId: number, callback: () => any, onError: () => any) {
    this.http.delete(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/schedule/${categoryId}/${scheduleId}`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getReservationCategorySchedule(scheduleId: number, categoryId: number, callback: () => any, onError: () => any) {
    this.http.get(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/schedule/${categoryId}/${scheduleId}`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getReservationCategorySchedules(categoryId: number, callback: (data: ReservationSchedule[]) => any, onError: () => any) {
    this.http.get(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/schedule/${categoryId}`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  updateReservationCategories(ids: number[], callback: () => any, onError: () => any) {
    this.http.put(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/categories/`, ids)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  updateReservationQuestionaire(questions: ReservationQuestion[]) {
    return this.http.put(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservationQuestionaire/`, questions);
  }

  getReservationQuestionaire(withDeleted: boolean, callback: (data: ReservationQuestion[]) => any, onError: () => any) {
    this.http.get(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservationQuestionaire/${withDeleted}`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  deleteQuestion(id: number, callback: () => any, onError: () => any) {
    this.http.delete(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservationQuestionaire/${id}`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getReservationLimits(): Observable<ReservationLimit[]> {
    return this.http.get<ReservationLimit[]>(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/limits`);
  }

  updateReservationLimits(reservationLimits: ReservationLimit[]) {
    return this.http.put(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/limits`, reservationLimits);
  }

  exportReservationsToFile(startDate: Date, endDate: Date, onNext: () => any, onError: () => any) : Observable<any> {
    var httpheaders = new HttpHeaders();
    httpheaders = httpheaders.set('startDate', startDate.toISOString()); // on hour earlier => one day erlier on server. bad....
    httpheaders = httpheaders.set('endDate', endDate.toISOString());
    const httpOptions = {
      responseType: 'blob' as 'json'
    };

    return this.http.get<any>(this.appBase +
      `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/reservation/export/`, { headers: httpheaders, responseType: 'blob' as 'json' });
  }

}
