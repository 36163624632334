<div #pagetop class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 *ngIf="!isEditForm" class="col-9 m-0 font-weight-bold text-primary">{{ 'add-desk' | translate }}</h5>
            <h5 *ngIf="isEditForm" class="col-9 m-0 font-weight-bold text-primary">{{ 'edit-desk' | translate }}</h5>
            <div class="col-3 text-right">
                <mat-slide-toggle color="primary" [checked]="advancedMode" (change)="advancedMode = !advancedMode">{{
                    'advanced-mode' | translate }}</mat-slide-toggle>
            </div>
        </div>
    </div>
    <div *ngIf="desk && deskCategoryMultiCtrl" class="card-body">
        <form #form="ngForm" autocomplete="off">
            <div class="form-group mt-2">
                <div class="input-group">
                    *<div class="font-weight-bold">{{ 'desk-name' | translate }}</div>
                </div>
                <div class="input-group">
                    <input name="name" #name="ngModel" [(ngModel)]="desk.name" class="form-control" required>
                </div>
                <div *ngIf="name.invalid && (name.dirty || name.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="name.errors.required">{{ 'desk-name-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    *<div class="font-weight-bold">{{ 'desk-number' | translate }}</div>
                </div>
                <div class="input-group">
                    <input name="deskNumber" #deskNumber="ngModel" [(ngModel)]="desk.deskNumber" class="form-control"
                        maxlength="4" required>
                </div>
                <div *ngIf="deskNumber.invalid && (deskNumber.dirty || deskNumber.touched)"
                    class="text-danger mx-2 my-1">
                    <div *ngIf="deskNumber.errors.required">{{ 'desk-number-required' | translate }}</div>
                    <div *ngIf="deskNumber.errors.maxlength">{{ 'desk-number-error' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    *<div class="font-weight-bold">{{ 'desk-pronunciation' | translate }}</div>
                </div>
                <div class="input-group">
                    <textarea name="deskFullNamePronunciation" #deskFullNamePronunciation="ngModel"
                        [(ngModel)]="desk.deskFullNamePronunciation" class="form-control" required></textarea>
                </div>
                <div class="text-danger mx-2 my-1" *ngIf="deskFullNamePronunciation.invalid &&
                    (deskFullNamePronunciation.dirty || deskFullNamePronunciation.touched)">
                    <div *ngIf="deskFullNamePronunciation.errors.required">{{ 'desk-pronunciation-required' | translate
                        }}</div>
                </div>
            </div>

            <div *ngIf="advancedMode">
                <div class="form-group mt-2">
                    {{ 'room-number' | translate }}
                    <div class="input-group">
                        <input name="roomNumber" #roomNumber="ngModel" [(ngModel)]="desk.roomNumber"
                            class="form-control" pattern="^[0-9]*$">
                    </div>
                    <div *ngIf="roomNumber.invalid && (roomNumber.dirty || roomNumber.touched)"
                        class="text-danger mx-2 my-1">
                        <div *ngIf="roomNumber.errors.pattern">{{ 'room-number-error' | translate }}</div>
                    </div>
                </div>

                <div class="form-group">
                    {{ 'room-number-pronunciation' | translate }}
                    <div class="input-group">
                        <textarea name="roomNumberPronunciation" #roomNumberPronunciation="ngModel"
                            [(ngModel)]="desk.roomNumberPronunciation" class="form-control"></textarea>
                    </div>
                </div>
            </div>

            <!-- TODO: Use served categories -->
            <div class="form-group">
                <div class="input-group">
                    *<div class="font-weight-bold">{{ 'served-categories' | translate }}</div>
                </div>
                <div class="input-group">
                    <mat-form-field color="primary">
                        <mat-select [formControl]="deskCategoryMultiCtrl" [multiple]="true" #multiSelect required>
                            <mat-option>
                                <ngx-mat-select-search [showToggleAllCheckbox]="true"
                                    (toggleAll)="toggleSelectAll($event)" [formControl]="deskCategoryMultiFilterCtrl"
                                    toggleAllCheckboxTooltipMessage="{{ 'select-unselect-all' | translate }}"
                                    [toggleAllCheckboxTooltipPosition]="'above'"
                                    placeholderLabel="{{ 'search-category' | translate }}"
                                    noEntriesFoundLabel="{{ 'no-matching-category' | translate }}">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let deskCategory of filteredDeskCategoriesMulti | async"
                                [value]="deskCategory">
                                <b>{{ deskCategory.tag }}</b>
                                {{ deskCategory.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="deskCategoryMultiCtrl.invalid && (deskCategoryMultiCtrl.dirty || deskCategoryMultiCtrl.touched)"
                    class="text-danger mx-2">
                    <div *ngIf="deskCategoryMultiCtrl.value == null || deskCategoryMultiCtrl?.value.length == 0">
                        {{ 'no-selected-categories' | translate }}</div>
                </div>
                <ul *ngFor="let deskCategory of deskCategoryMultiCtrl?.value">
                    <li class="item-group">
                        <app-category-button [categoryTag]="deskCategory.tag"></app-category-button>
                        {{ deskCategory.name }},
                        {{ 'priority' | translate }}:
                        <mat-radio-group color="primary">
                            <mat-radio-button value="1" (change)="setCategoryPriority(deskCategory, 1)" class="mx-1"
                                [checked]="deskCategory.categoryPriority == categoryPriority.High">
                                {{ 'high' | translate }}
                            </mat-radio-button>
                            <mat-radio-button value="2" (change)="setCategoryPriority(deskCategory, 2)" class="mx-1"
                                [checked]="deskCategory.categoryPriority == categoryPriority.Mid">
                                {{ 'mid' | translate }}
                            </mat-radio-button>
                            <mat-radio-button value="3" (change)="setCategoryPriority(deskCategory, 3)" class="mx-1"
                                [checked]="deskCategory.categoryPriority == categoryPriority.Low">
                                {{ 'low' | translate }}
                            </mat-radio-button>
                        </mat-radio-group>
                        <hr>
                    </li>
                </ul>
            </div>

            <div class="form-group row">
                <button class="btn btn-danger m-1" routerLink="/desks">{{ 'back' | translate }}</button>
                <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid ||
                    deskCategoryMultiCtrl.value == null || deskCategoryMultiCtrl?.value.length == 0"
                    (click)="onSubmit(deskCategoryMultiCtrl?.value)">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>{{ 'save-desk' | translate }}
                </button>
                <button *ngIf="isEditForm" class="btn btn-info m-1" type="submit" [disabled]="formSubmitted || form.invalid ||
                    deskCategoryMultiCtrl.value == null || deskCategoryMultiCtrl?.value.length == 0"
                    (click)="isEditForm = false; pagetop.scrollIntoView({behavior: 'smooth'})">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>{{ 'new-desk-based-on-existing' | translate }}
                </button>
            </div>
            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>