import { LocationConstraints } from "./location-constraints";

export class LocationData {
    id: number;
    systemId: number;
    locationName: string;
    country: string;
    city: string;
    street: string;
    buildingNumber: string;
    postCode: string;
    phoneNumber: string;
    website: string;
    latitude: number;
    longitude: number;
    logoImgPath: string;
    constraints: LocationConstraints;
    locationLocaleMain: string;
    multiLanguageSystem: boolean;
    readTicketAlwaysInOriginLangauge: boolean;
    timeZone: string;
    ticketResetTaskTime: any;
    categoryUnblockerTaskTime: any;
    eticketChangeCodeAfter24h: boolean;
    isWcag: boolean;
}
