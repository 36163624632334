<div *ngFor="let reportTemplateParameter of reportTemplateParameters; let i = index">
    <div class="row">
        <app-report-template-parameter *ngIf="reportTemplateParameter" class="col-11" [reportTemplateParameter]="reportTemplateParameter"
            [users]="users" [desks]="desks" [categories]="categories">
        </app-report-template-parameter>
        <div class="col-1">
            <button class="btn btn-circle btn-danger" (click)="deleteParameter(i)"><i class="fas fa-fw fa-trash"></i></button>
        </div>
    </div>
</div>

<button class="btn btn-sm btn-info" (click)="addParameter()">{{ 'add-new-parameter' | translate }}</button>
