import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Category } from 'projects/qcloud-models/category/category.model';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { TicketHistoryParams } from 'projects/qcloud-models/statistics/ticket-history-params';
import { ReportService } from 'projects/qcloud-rest-client/src/lib/report.service';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { User } from 'projects/qcloud-models/user/user.model';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TicketHistoryLog } from 'projects/qcloud-models/statistics/ticket-history-log';
import { DateAdapter } from '@angular/material/core';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { TimeConversionService } from 'projects/qcloud-web/src/app/shared/time-conversion.service';
import { TicketState } from 'projects/qcloud-models/console/ticketState.enum';

@Component({
  selector: 'app-ticket-history-statistics',
  templateUrl: './ticket-history-statistics.component.html',
  styleUrls: ['./ticket-history-statistics.component.css']
})
export class TicketHistoryStatisticsComponent implements OnInit {

  categories: Category[];
  categoryTag: string;
  desks: Desk[];
  categoryMultiCtrl: UntypedFormControl;
  servedCategories: string = 'served-categories';
  selectCategory: string = 'select-category';
  noSelectedCategories = 'no-selected-categories';
  noSelectedElements = 'no-selected-elements';

  ticketParams: TicketHistoryParams;
  dateCtrl: UntypedFormControl;
  ticketHistoryData: Array<any> = new Array();
  placeholder: string = "placeholder";
  nomatch: string = "nomatch";

  users: User[];

  constructor(private categoryService: CategoryService, private reportService: ReportService,
    private userService: UserService, private translate: TranslateService,
    private toastr: ToastrService, private _adapter: DateAdapter<any>,
    private cdr: ChangeDetectorRef, private deskService: DeskService,
    private timeService: TimeConversionService) {
    this._adapter.setLocale(this.translate.currentLang);
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this._adapter.setLocale(event.lang);
      this.cdr.detectChanges();
    });
  }



  ngOnInit(): void {
    this.categoryService.getCategories(
      (data) => {
        this.categories = data;
        this.categoryMultiCtrl = new UntypedFormControl(this.categories);
      },
      () => { }
    );
    this.deskService.getDesks(
      (data) => {
        this.desks = data;
      },
      () => { }
    )
    this.dateCtrl = new UntypedFormControl();
    this.ticketParams = new TicketHistoryParams();
  }

  getTicketHistory() {
    this.ticketParams.categoryId = this.categoryMultiCtrl.value.id;
    this.categoryTag = this.categories.find(category => category.id == this.categoryMultiCtrl.value.id).categoryTag;
    this.ticketParams.date = this.timeService.dateToDateWithoutTime(this.dateCtrl.value);
    this.ticketParams.ticketNumber = +this.ticketParams.ticketNumber;
    this.reportService.getTicketHistory(this.ticketParams).subscribe(data => {
      if (data.length == 0) {
        this.translate.get('no-matching-tickets-error').subscribe(res => {
          this.toastr.error(res)
        })
        return;
      }
      this.userService.getUsers(users => {
        this.ticketHistoryData = new Array(data.length);
        this.users = users;
        this.ticketHistoryData = data.map(ticketData => {
          let newTicketData = new TicketHistoryLog(ticketData);
          newTicketData.ticketNumber = this.categoryTag + newTicketData.ticketNumber;
          newTicketData.eventType = this.translateTicketState(ticketData.newState);
          let user = this.users.find(user => user.id == ticketData.calledById);
          let desk = this.desks.find(desk => desk.id == newTicketData.deskId);
          let redirectedCategory = this.categories.find(category => category.id == newTicketData.categoryId);
          let redirectedDesk = this.desks.find(desk => desk.id == newTicketData.redirectedDeskId);
          let ticketTranslation = this.translateTicket();
          let byUserTranslation = this.translateUser();
          let onDeskTranslation = this.translateDesk();
          let redirectedDestination = "";
          let redirectedCategoryName = "";
          if (newTicketData.newState == TicketState.RedirectedToCategory) {
            redirectedCategoryName = redirectedCategory.fullName;
          }
          if (newTicketData.newState == TicketState.RedirectedToDesk) {
            redirectedDestination = redirectedDesk.name;
          }
          let info = ticketTranslation + " " + newTicketData.eventType + " "
            + redirectedDestination + " " + redirectedCategoryName + " ";
          if (user) {
            newTicketData.user = user;
            info += byUserTranslation + " " + user.firstName + " " + user.lastName + " " +
              onDeskTranslation + " " + desk.name + " " + desk.deskNumber;
          }
          newTicketData.info = info;
          return newTicketData;
        })
      }, () => { })
    })
  }

  translateTicket() {
    var translation = "";
    this.translate.get('ticket').subscribe(res => {
      translation = res;
      return res;
    })
    return translation;
  }

  translateUser() {
    var translation = "";
    this.translate.get('by-user').subscribe(res => {
      translation = res;
      return res;
    })
    return translation;
  }

  translateDesk() {
    var translation = "";
    this.translate.get('at-desk').subscribe(res => {
      translation = res;
      return res;
    })
    return translation;
  }

  translateTicketState(status: number) {
    var translation = "";
    switch (status) {
      case TicketState.New:
        this.translate.get('created-status').subscribe(res => {
          translation = res;
          return res;
        })
        return translation;
      case TicketState.Accepted:
        this.translate.get('accepted-status').subscribe(res => {
          translation = res;
          return res;
        })
        return translation;
      case TicketState.Cancelled:
        this.translate.get('cancelled-status').subscribe(res => {
          translation = res;
          return res;
        })
        return translation;
      case TicketState.RedirectedToCategory:
        this.translate.get('redirected-to-category-status').subscribe(res => {
          translation = res;
          return res;
        })
        return translation;
      case TicketState.Called:
        this.translate.get('called-status').subscribe(res => {
          translation = res;
          return res;
        })
        return translation;
      case TicketState.RedirectedToDesk:
        this.translate.get('redirected-to-desk-status').subscribe(res => {
          translation = res;
          return res;
        })
        return translation;
      case TicketState.PausedForTime:
        this.translate.get('paused-for-time-status').subscribe(res => {
          translation = res;
          return res;
        })
        return translation;
      case TicketState.PausedIndefinitely:
        this.translate.get('paused-indefinitely-status').subscribe(res => {
          translation = res;
          return res;
        })
        return translation;
      default:
        return "";
    }
  }

}
