import { Component, OnInit } from '@angular/core';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { Category } from 'projects/qcloud-models/category/category.model';
import { TranslateService } from '@ngx-translate/core';
import { AxxonIntelectIntegrationService } from 'projects/qcloud-rest-client/src/lib/axxon-intelect-integration.service';
import { AxxonIntelectIntegrationCategoryFacilityCode } from 'projects/qcloud-models/integration/axxon-intelect-integration';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-axxon-intelect-integration',
  templateUrl: './axxon-intelect.component.html',
  styleUrls: ['./axxon-intelect.component.css']
})
export class AxxonIntelectIntegrationPageComponent implements OnInit {

  categories: any[];
  axxonData: AxxonIntelectIntegrationCategoryFacilityCode[];

  constructor(private categoryService: CategoryService,
    private translate: TranslateService,
    private axxonService: AxxonIntelectIntegrationService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.axxonService.getAxxon((data) => {
      this.axxonData = data;
      this.categoryService.getCategories((data) => {
        this.categories = data;
      },
        () => { });
    },
      () => { });
  }

  public saveCodes() {
    this.axxonService.saveAxxon(this.axxonData, (data) => {
      this.toastr.success("Zapisano zmiany");
      this.axxonService.getAxxon((data) => {
        this.axxonData = data;
      },()=>{});
    },
      () => { 
        this.toastr.error("Wystąpił błąd");
      });
  }

  public remove(index: number) {
    let item = this.axxonData[index];
    if (item.id != undefined) {
      this.axxonService.deleteAxxon(item.id, (data) => {
        this.axxonData.splice(index, 1);
      },
        () => { });
    }
    else{
      this.axxonData.splice(index, 1);
    }

  }

  public addNew() {
    this.axxonData.push(new AxxonIntelectIntegrationCategoryFacilityCode());
  }
}
