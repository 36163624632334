import { DeviceType } from './device-type.enum';
import { TicketLayoutType } from './ticket-layout-type.enum';
import { CategoryDisplayNameType } from './category-display-name-type.enum';
import { SayingTicketsTypes } from './saying-tickets-types.enum';
import { DeviceDeskIcon } from './device-desk-icon.model';
import { DeviceCustomization } from './device-customization.model';
import { DeviceCategoryTree } from '../categoryTree/device-category-tree.model';
import { ActivityTime } from '../activity-time';
import { TicketGenerationType } from './ticket-generation-type.enum';

export class Device {
    public id: number;
    public guid: string;
    public deviceName: string;
    public deviceType: DeviceType;
    public activated: boolean;
    public activationCode: string;
    public systemId: number;
    public locationId: number;
    public deskId: number;
    public layoutType: number;
    public ticketLayoutType: TicketLayoutType;
    public categoryDisplayNameType: CategoryDisplayNameType;
    public availableCategories: any[];
    public availableDesks: any[];
    public desks: any[];
    public sayingTicketsType: SayingTicketsTypes;
    public canPrintReservedTickets: boolean;
    public canMakeReservations: boolean;
    public isOutOfPaper: boolean;
    public canShowQueue: boolean;
    public canShowAvgWaitTime: boolean;
    public showDeskIcon: boolean;
    public isAlive: boolean;
    public isAliveLastTime: Date;
    public reservationButtonText: string;
    public makeReservationButtonText: string;
    public title: string;
    public deviceCustomization: DeviceCustomization;
    public deviceDeskIcons: DeviceDeskIcon[];
    public deviceCategoryTree: DeviceCategoryTree;
    public deviceActivityTimes: ActivityTime[];
    public ticketGenerationType: TicketGenerationType;
}
