<div #pagetop class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 *ngIf="!isEditForm" class="col-9 m-0 font-weight-bold text-primary">
                {{ 'add-category' | translate }}
            </h5>
            <h5 *ngIf="isEditForm" class="col-9 m-0 font-weight-bold text-primary">
                {{ 'edit-category' | translate }}
            </h5>
            <div class="col-3 text-right">
                <mat-slide-toggle color="primary" [checked]="advancedMode" (change)="advancedMode = !advancedMode">
                    {{ 'advanced-mode' | translate }}
                </mat-slide-toggle>
            </div>
        </div>
    </div>
    <div *ngIf="category" class="card-body">
        <form #form="ngForm" autocomplete="off">
            <div class="form-group">
                <div class="input-group">
                    *<div class="font-weight-bold">{{ 'category-name' | translate }}</div>
                </div>
                <div class="input-group">
                    <input name="fullName" #fullName="ngModel" [(ngModel)]="category.fullName"
                        class="form-control" required>
                </div>
                <div *ngIf="fullName.invalid && (fullName.dirty || fullName.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="fullName.errors.required">{{ 'category-name-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    *<div class="font-weight-bold">{{ 'short-name' | translate }}</div>
                </div>
                <div class="input-group">
                    <input name="shortName" #shortName="ngModel" [(ngModel)]="category.shortName"
                        class="form-control" required>
                </div>
                <div *ngIf="shortName.invalid && (shortName.dirty || shortName.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="shortName.errors.required">{{ 'short-name-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                <div class="input-group">
                    *<div class="font-weight-bold">{{ 'tag' | translate }}</div>
                </div>
                <div class="input-group">
                    <input name="categoryTag" #categoryTag="ngModel" [(ngModel)]="category.categoryTag"
                        class="form-control" maxlength="2" required>
                </div>
                <div *ngIf="categoryTag.invalid && (categoryTag.dirty || categoryTag.touched)" class="text-danger mx-2 my-1">
                    <div *ngIf="categoryTag.errors.required">{{ 'tag-required' | translate }}</div>
                </div>
            </div>

            <div class="form-group">
                <span class="font-weight-bold">{{ 'daily-ticket-limit' | translate }} </span>
                <mat-slide-toggle color="primary" [checked]="checkedTicketLimit"
                    (change)="checkedTicketLimit = !checkedTicketLimit"></mat-slide-toggle>
                <div *ngIf="!checkedTicketLimit">
                    <label>{{ 'default-no-limit' | translate }}</label>
                </div>
                <div *ngIf="checkedTicketLimit">
                    <input name="ticketLimit" #ticketLimit="ngModel" [(ngModel)]="category.ticketLimit"
                        class="form-control" type="number" required>
                    <div *ngIf="ticketLimit.invalid && (ticketLimit.dirty || ticketLimit.touched)" class="text-danger mx-2 my-1">
                        <div *ngIf="ticketLimit.errors">{{ 'ticket-limit-error' | translate }}</div>
                    </div>
                </div>
            </div>

            <div class="form-group" *ngIf="advancedMode">
                <span class="font-weight-bold">{{ 'time-per-ticket' | translate }} </span>
                <mat-slide-toggle color="primary" [checked]="checkedTimePerTicket"
                    (change)="checkedTimePerTicket = !checkedTimePerTicket"></mat-slide-toggle>
                <div *ngIf="!checkedTimePerTicket">
                    <label>{{ 'default-no-limit' | translate }}</label>
                </div>
                <div *ngIf="checkedTimePerTicket">
                    <input name="timePerTicket" #timePerTicket="ngModel" [(ngModel)]="category.timePerTicket"
                        class="form-control" type="number" required>
                    <div *ngIf="timePerTicket.invalid && (timePerTicket.dirty || timePerTicket.touched)" class="text-danger mx-2 my-1">
                        <div *ngIf="timePerTicket.errors">{{ 'time-per-ticket-error' | translate }}</div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <div class="font-weight-bold">{{ 'category-activity' | translate }}</div>
                <app-weekly-activity [unblockingTimeDaysValue]="category.unblockingTimeDaysValue"
                    [nightWork]="nightWork">
                </app-weekly-activity>
            </div>

            <app-category-activity-blocks *ngIf="isEditForm" [categoryId]="category.id"></app-category-activity-blocks>

            <div class="form-group">
                <div class="font-weight-bold">{{ 'blocking-category-activity-message' | translate }}</div>
                <div class="input-group">
                    <input name="blockingMessageActivity" #blockingMessageActivity="ngModel"
                        [(ngModel)]="category.blockingMessageActivity" class="form-control">
                </div>
            </div>

            <div class="form-group">
                <div class="font-weight-bold">{{ 'manual-blocking-category-message' | translate }}</div>
                <div class="input-group">
                    <input name="blockingMessageManual" #blockingMessageManual="ngModel"
                        [(ngModel)]="category.blockingMessageManual" class="form-control">
                </div>
            </div>

            <div class="form-group">
                <div class="item-group">
                    <mat-checkbox name="isAutomaticallyReset" #isAutomaticallyReset="ngModel"
                        [(ngModel)]="category.isAutomaticallyReset" class="font-weight-bold"
                        color="primary">{{ 'automatic-reset' | translate }}</mat-checkbox>
                </div>
            </div>

            <div *ngIf="advancedMode">
                <p class="font-weight-bold text-primary">{{ 'advanced-mode' | translate }}</p>

                <div class="form-group">
                    {{ 'set-max-service-time' | translate }}
                    <input *ngIf="checkedService" name="maxServiceTime" #maxServiceTime="ngModel"
                        [(ngModel)]="category.maxServiceTime" class="input-slider form-control" type="number" required
                        (change)="checkServiceTimeInput(category.maxServiceTime)">({{ 'minutes-shortcut' | translate }})
                    <mat-slide-toggle color="primary" [checked]="checkedService"
                        (change)="checkedService = !checkedService"></mat-slide-toggle>
                    <div *ngIf="!checkedService">
                        <label>{{ 'default-no-limit' | translate }}</label>
                    </div>
                    <div *ngIf="checkedService">
                        <mat-slider [max]="maxService" [min]="minService" color="primary"
                            name="maxServiceTime" #maxServiceTime="ngModel" [(ngModel)]="category.maxServiceTime">
                        </mat-slider>
                        <div *ngIf="maxServiceTime.invalid" class="text-danger mb-1">
                            <div *ngIf="maxServiceTime.errors">{{ 'max-service-time-error' | translate }}</div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    {{ 'set-default-pause-time' | translate }}
                    <input *ngIf="checkedPause" name="defaultPauseTimeMins" #defaultPauseTimeMins="ngModel"
                        [(ngModel)]="category.defaultPauseTimeMins" class="input-slider form-control" type="number" required
                        (change)="checkPauseTimeInput(category.defaultPauseTimeMins)">(min)
                    <mat-slide-toggle color="primary" [checked]="checkedPause"
                        (change)="checkedPause = !checkedPause">
                    </mat-slide-toggle>
                    <div *ngIf="!checkedPause">
                        <label>{{ 'default-no-pause' | translate }}</label>
                    </div>
                    <div *ngIf="checkedPause">
                        <mat-slider [max]="maxPause" [min]="minPause" [step]="step"
                            color="primary" name="defaultPauseTimeMins" #defaultPauseTimeMins="ngModel"
                            [(ngModel)]="category.defaultPauseTimeMins">
                        </mat-slider>
                        <div *ngIf="defaultPauseTimeMins.invalid" class="text-danger mb-1">
                            <div *ngIf="defaultPauseTimeMins.errors">{{ 'default-pause-time-error' | translate }}</div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox name="isNoteRequired" #isNoteRequired="ngModel" [(ngModel)]="category.isNoteRequired"
                            color="primary">{{ 'ticket-note' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox *ngIf="locationService.getLocationConstraints().areEnabledTicketsReservations"
                            name="canBeReserved" #canBeReserved="ngModel" [(ngModel)]="category.canBeReserved"
                            color="primary">{{ 'can-be-reserve' | translate }}
                        </mat-checkbox>

                        <mat-checkbox *ngIf="!locationService.getLocationConstraints().areEnabledTicketsReservations" disabled
                            matTooltipPosition="right" matTooltipClass="tooltip-orange"
                            matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                            {{ 'can-be-reserve' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox *ngIf="locationService.getLocationConstraints().areETicketsEnabled"
                            name="eTicketRedirect" #eTicketRedirect="ngModel" [(ngModel)]="category.eTicketRedirect"
                            color="primary">{{ 'eticket-redirect' | translate }}
                        </mat-checkbox>

                        <mat-checkbox *ngIf="!locationService.getLocationConstraints().areETicketsEnabled" disabled
                            matTooltipPosition="right" matTooltipClass="tooltip-orange"
                            matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                            {{ 'eticket-redirect' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox *ngIf="locationService.getLocationConstraints().isOnHoursEnabled"
                            name="eTicketRedirect" #eTicketRedirect="ngModel" [(ngModel)]="category.canOnHour"
                            color="primary">{{ 'on-hours' | translate }}
                        </mat-checkbox>

                        <mat-checkbox *ngIf="!locationService.getLocationConstraints().isOnHoursEnabled" disabled
                            matTooltipPosition="right" matTooltipClass="tooltip-orange"
                            matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                            {{ 'on-hours' | translate }}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox *ngIf="locationService.getLocationConstraints().isSmsEnabled"
                            name="smsCategory" #smsCategory="ngModel" [(ngModel)]="category.smsCategory"
                            color="primary">Sms
                        </mat-checkbox>

                        <mat-checkbox *ngIf="!locationService.getLocationConstraints().isSmsEnabled" disabled
                            matTooltipPosition="right" matTooltipClass="tooltip-orange"
                            matTooltip="{{ 'option-available-after-extending-licence' | translate }}">
                            Sms
                        </mat-checkbox>

                        <div *ngIf="category.smsCategory" class="ml-3 form-group">
                            <div class="item-group">
                                <mat-checkbox name="sendSmsOnReservation" #sendSmsOnReservation="ngModel"
                                    [(ngModel)]="category.sendSmsOnReservation" color="primary">
                                    {{ 'sending-sms-on-reservation' | translate }}
                                </mat-checkbox>
                            </div>
                            <div class="item-group">
                                <mat-checkbox name="sendSmsOnPrint" #sendSmsOnPrint="ngModel"
                                    [(ngModel)]="category.sendSmsOnPrint" color="primary">
                                    {{ 'sending-sms-on-print-ticket' | translate }}
                                </mat-checkbox>
                            <div class="item-group">
                            </div>
                                <mat-checkbox name="sendOnBeforeCall" #sendSmsOnBeforeCall="ngModel"
                                    [(ngModel)]="category.sendSmsOnBeforeCall" color="primary">
                                    {{ 'sending-sms-on-before-ticket-call' | translate }}
                                </mat-checkbox>
                            </div>
                            <div class="item-group">
                                <mat-checkbox name="sendSmsOnTicketCall" #sendSmsOnTicketCall="ngModel"
                                    [(ngModel)]="category.sendSmsOnTicketCall" color="primary">
                                    {{ 'sending-sms-on-ticket-call' | translate }}
                                </mat-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox *ngIf="!category.conditionalTicket" color="primary" name="avgTimeBlock"
                            #avgTimeBlock="ngModel" [(ngModel)]="category.avgTimeBlock">
                            {{ 'smart-block' | translate }}
                        </mat-checkbox>

                        <mat-checkbox *ngIf="category.conditionalTicket" disabled
                            matTooltipPosition="right" matTooltipClass="tooltip-info"
                            matTooltip="{{ 'turn-off-conditional-ticket' | translate }}">
                            {{ 'smart-block' | translate }}
                        </mat-checkbox>
                    </div>
                    <div *ngIf="category.avgTimeBlock" class="input-group">
                        <input name="avgTimeBlockText" #avgTimeBlockText="ngModel"
                            [(ngModel)]="category.avgTimeBlockText" class="form-control"
                            placeholder="{{ 'smart-block-text' | translate}}">
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox *ngIf="!category.avgTimeBlock" color="primary" name="conditionalTicket"
                            #conditionalTicket="ngModel" [(ngModel)]="category.conditionalTicket">
                            {{ 'conditional-ticket' | translate }}
                        </mat-checkbox>

                        <mat-checkbox *ngIf="category.avgTimeBlock" disabled
                            matTooltipPosition="right" matTooltipClass="tooltip-info"
                            matTooltip="{{ 'turn-off-smart-block' | translate }}">
                            {{ 'conditional-ticket' | translate }}
                        </mat-checkbox>
                    </div>
                    <div *ngIf="category.conditionalTicket" class="input-group">
                        <input name="conditionalTicketText" #conditionalTicketText="ngModel"
                            [(ngModel)]="category.conditionalTicketText" class="form-control"
                            placeholder="{{ 'conditional-ticket-text' | translate}}">
                    </div>
                </div>

                <div class="form-group">
                    {{ 'additional-text-ticket' | translate }}
                    <div class="input-group">
                        <input name="printedTicketAdditionalText" #printedTicketAdditionalText="ngModel"
                            [(ngModel)]="category.printedTicketAdditionalText" class="form-control">
                    </div>
                </div>

                <div class="form-group">
                    <div class="item-group">
                        <mat-checkbox name="canMobile" #canMobile="ngModel" [(ngModel)]="category.canMobile"
                            color="primary">{{ 'mobile-app' | translate }}</mat-checkbox>
                    </div>
                </div>

                <div class="form-group">
                    {{ 'number-of-recent-tickets-used-to-count-average-service-and-waiting-times' | translate }}
                    <div class="item-group">
                        <input type="number" class="form-control" name="ticketAmountToCountAverageWaitingServiceTime"
                            #ticketAmountToCountAverageWaitingServiceTime="ngModel"
                            [(ngModel)]="category.ticketAmountToCountAverageWaitingServiceTime">
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <button class="btn btn-danger m-1" routerLink="/categories">{{ 'back' | translate }}</button>
                <button class="btn btn-primary m-1" type="submit" [disabled]="formSubmitted || form.invalid"
                    (click)="onSubmit()">
                <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                    aria-hidden="true"></span>{{ 'save-category' | translate }}
                </button>
                <button *ngIf="isEditForm" class="btn btn-info m-1" type="submit"
                    [disabled]="formSubmitted || form.invalid" (click)="newBasedOnExisting();
                    pagetop.scrollIntoView({behavior: 'smooth'})">
                    <span *ngIf="formSubmitted" class="spinner-border spinner-border-sm my-1" role="status"
                        aria-hidden="true"></span>{{ 'new-category-based-on-existing' | translate }}
                </button>
            </div>
            <label *ngIf="form.invalid" class="text-danger">*{{ 'complete-required-fields' | translate }}</label>
        </form>
    </div>
</div>