<div class="card shadow mb-4">
    <div class="card-header py-3">
        <div class="row">
            <h5 class="col-8 m-0 font-weight-bold text-primary">{{ 'layouts' | translate }}</h5>
        </div>
    </div>
    <div class="card-body">
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <td>#</td>
                        <td>{{ 'layoutName' | translate }}</td>
                        <td>{{ 'layoutContent' | translate }}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let layout of deviceLayouts; let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{ getLayoutName(layout.layoutNumber) }}</td>
                        <td>
                            <input name="layoutJson" #layoutJson="ngModel" [(ngModel)]="layout.layoutJson"
                                class="form-control" required>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <button class="btn btn-primary m-1" type="submit" (click)="onSubmit()">
            {{ 'save' | translate }}
        </button>
    </div>
</div>