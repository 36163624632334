<div class="card shadow mb-3">
    <div class="card-header py-2 d-flex flex-row align-items-center justify-content-between">
        <h6 class="m-0 font-weight-bold text-primary">{{ title | translate }}</h6>
        <lib-close-all-modals-button></lib-close-all-modals-button>
    </div>
    <div class="card-body">
        <container-element [ngSwitch]="true">
            <lib-redirect-modal *ngSwitchCase="redirectModal.isOpen"></lib-redirect-modal>
            <lib-redirect-to-category-modal *ngSwitchCase="redirectToCategoryModal.isOpen"></lib-redirect-to-category-modal>
            <lib-redirect-to-desk-modal *ngSwitchCase="redirectToDeskModal.isOpen"></lib-redirect-to-desk-modal>
            <lib-for-hour-chooser *ngSwitchCase="redirectToForHourModal.isOpen"></lib-for-hour-chooser>
            <lib-cancelled-tickets-modal *ngSwitchCase="cancelledTicketsModal.isOpen"></lib-cancelled-tickets-modal>
            <lib-pause-ticket-modal *ngSwitchCase="pauseModal.isOpen"></lib-pause-ticket-modal>
            <lib-paused-tickets-modal *ngSwitchCase="pausedTicketsModal.isOpen"></lib-paused-tickets-modal>
            <lib-generate-ticket-modal *ngSwitchCase="generateTicketModal.isOpen"></lib-generate-ticket-modal>
            <lib-note-ticket-modal *ngSwitchCase="noteTicketModal.isOpen"></lib-note-ticket-modal>
            <lib-call-ticket-manual-modal *ngSwitchCase="manualCallTicketModal.isOpen"></lib-call-ticket-manual-modal>
            <lib-blocked-categories-modal *ngSwitchCase="blockedCategoriesModal.isOpen"></lib-blocked-categories-modal>
            <lib-served-categories-modal *ngSwitchCase="servedCategoriesModal.isOpen"></lib-served-categories-modal>
            <lib-logout-modal *ngSwitchCase="logoutModal.isOpen"></lib-logout-modal>
            <lib-show-ticket-manual-modal *ngSwitchCase="manualShowTicketModal.isOpen"></lib-show-ticket-manual-modal>
            <div *ngSwitchDefault>{{ 'console.here-perform-operations-available-in-console' | translate }}</div>
          </container-element>
    </div>
</div>