<div class="d-flex flex-row align-items-center justify-content-between">
    <h6 [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}">{{ 'console.interaction-card' | translate }}</h6>
    <lib-close-all-modals-button [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}" (hideInteractionModel)="hideInteractionModelCard()"></lib-close-all-modals-button>
</div>
<container-element [ngSwitch]="true" [ngClass]="{'': consoleService.projectTypeEnum.WEB === this.consoleService.projectType , 'device-font-size': this.consoleService.projectType === this.consoleService.projectTypeEnum.DEVICE}">
    <lib-redirect-modal *ngSwitchCase="redirectModal.isOpen"></lib-redirect-modal>
    <lib-redirect-to-category-modal *ngSwitchCase="redirectToCategoryModal.isOpen" (hideInteractionModel)="hideInteractionModelCard()"></lib-redirect-to-category-modal>
    <lib-redirect-to-desk-modal *ngSwitchCase="redirectToDeskModal.isOpen" (hideInteractionModel)="hideInteractionModelCard()"></lib-redirect-to-desk-modal>
    <lib-for-hour-chooser *ngSwitchCase="redirectToForHourModal.isOpen"></lib-for-hour-chooser>
    <lib-cancelled-tickets-modal *ngSwitchCase="cancelledTicketsModal.isOpen" (hideInteractionModel)="hideInteractionModelCard()"></lib-cancelled-tickets-modal>
    <lib-pause-ticket-modal *ngSwitchCase="pauseModal.isOpen" (hideInteractionModel)="hideInteractionModelCard()"></lib-pause-ticket-modal>
    <lib-paused-tickets-modal *ngSwitchCase="pausedTicketsModal.isOpen" (hideInteractionModel)="hideInteractionModelCard()"></lib-paused-tickets-modal>
    <lib-generate-ticket-modal *ngSwitchCase="generateTicketModal.isOpen"></lib-generate-ticket-modal>
    <lib-note-ticket-modal *ngSwitchCase="noteTicketModal.isOpen"></lib-note-ticket-modal>
    <lib-call-ticket-manual-modal *ngSwitchCase="manualCallTicketModal.isOpen" (hideInteractionModel)="hideInteractionModelCard()"></lib-call-ticket-manual-modal>
    <lib-blocked-categories-modal *ngSwitchCase="blockedCategoriesModal.isOpen"></lib-blocked-categories-modal>
    <lib-served-categories-modal *ngSwitchCase="servedCategoriesModal.isOpen"></lib-served-categories-modal>
    <lib-logout-modal *ngSwitchCase="logoutModal.isOpen"></lib-logout-modal>
    <lib-show-ticket-manual-modal *ngSwitchCase="manualShowTicketModal.isOpen"></lib-show-ticket-manual-modal>
    <div *ngSwitchDefault>{{ 'console.here-perform-operations-available-in-console' | translate }}</div>
</container-element>