import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReportTemplate } from 'projects/qcloud-models/report/report-template';
import { ReportTemplateJson } from 'projects/qcloud-models/report/report-template-json';
import { TicketReportParams } from 'projects/qcloud-models/report/ticket-report-params';
import { TicketHistoryParams } from 'projects/qcloud-models/statistics/ticket-history-params';
import { Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  appBase: string;

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  constructor(private http: HttpClient, private authService: AuthService) { }

  generateReport(ticketReportParams: TicketReportParams): Observable<any> {
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    return this.http.post<any>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/report/generate`, ticketReportParams,
      httpOptions);
  }

  getTemplates(): Observable<ReportTemplateJson[]> {
    return this.http.get<ReportTemplateJson[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/template`);
  }

  createTemplate(reportTemplate: ReportTemplateJson) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/template`, reportTemplate);
  }

  editTemplate(reportTemplate: ReportTemplateJson) {
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/template`, reportTemplate);
  }

  deleteTemplate(reportTemplateId: number) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/template/${reportTemplateId}`);
  }

  getTicketHistory(ticketHistoryParams: TicketHistoryParams): Observable<any>{
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/tickethistory`, ticketHistoryParams);
  }
}
