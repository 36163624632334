import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-blocked-categories-button',
  templateUrl: './blocked-categories-button.component.html',
  styleUrls: ['./blocked-categories-button.component.css',  './../base-button/base-button.component.css']
})
export class BlockedCategoriesButtonComponent  implements OnInit {
	@ViewChild('button') public button: ElementRef;
  isEnabled: boolean;
  hasPermission: boolean;
  isBlocked: boolean;

  constructor(private consoleService: QcloudConsoleService) {
    this.isEnabled = true;
  }

  ngOnInit(): void {
    this.hasPermission = this.consoleService.user.hasPermissionToBlockingCategoryManually;
  }

  public openBlockedCategoriesModal() {
    if (this.isEnabled) {
      this.consoleService.openBlockedCategoriesModal();
    }
  }

}
