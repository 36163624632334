import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { DeviceCategoryTreeValue } from "../../../../../../qcloud-models/categoryTree/device-category-tree-value.model";
import { Category } from 'projects/qcloud-models/category/category.model';
import { DeviceCategoryTree } from 'projects/qcloud-models/categoryTree/device-category-tree.model';
import { Node } from 'projects/qcloud-models/categoryTree/node.model';
import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { CdkDragDrop, moveItemInArray, copyArrayItem, CdkDragStart, CdkDragEnd } from '@angular/cdk/drag-drop';
import { DragAndDropListElement } from "./drag-and-drop-list-element.model";
import { ListType } from "./list-type.enum";

@Component({
  selector: 'app-dnd-categories',
  templateUrl: './app-drag-and-drop.component.html',
  styleUrls: ['./app-drag-and-drop.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DragAndDropComponent implements OnInit {

  @Input() categories: Category[];
  @Input() categoryTree: DeviceCategoryTree;
  @Input() maxDepth: number;
  sourceBuilderTools: DragAndDropListElement[] = [];
  targetBuilderTools: DragAndDropListElement[] = [];
  @Output() categoryTreeToEmit = new EventEmitter();
  deviceCategoryTree: DeviceCategoryTree = new DeviceCategoryTree();

  constructor(private toastr: ToastrService, private translate: TranslateService) { }

  ngOnInit(): void {
    if (this.categoryTree) {
      this.targetBuilderTools = this.convertToDnd(this.categoryTree.root);
    }
    this.sendCategoryTree();
    this.createSourceBuilderTools();
  }

  findCategoryNameByIdOrReturnDefault(id: number): string {
    return this.categories.filter(x => x.id == id)[0].fullName;
  }

  createSourceBuilderTools() {
    this.sourceBuilderTools = [];
    this.categories.forEach(category => {
      var item = DragAndDropListElement.CreateElementForSourceList(category);
      this.sourceBuilderTools.push(item);
    });
    this.translate.get('group').subscribe((group: string) => {
      var item = DragAndDropListElement.CreateGroup(group);
      this.sourceBuilderTools.unshift(item);
    })
  }

  sendCategoryTree() {
    this.deviceCategoryTree = new DeviceCategoryTree();
    this.convertToTree(this.targetBuilderTools, this.deviceCategoryTree.root, 0);
    this.categoryTreeToEmit.emit(this.deviceCategoryTree);
  }

  convertToTree(targetBuilderTools: DragAndDropListElement[], root: Node<DeviceCategoryTreeValue>, depth: number) {
    ++depth;
    for (var j = 0; j < targetBuilderTools.length; j++) {
      var element = targetBuilderTools[j];
      if (element.isGroup) {
        var children: DragAndDropListElement[] = [];
        for (var i = j + 1; i <= j + element.scopeSize; i++) {
          children.push(targetBuilderTools[i]);
        }
        j = j + element.scopeSize;
        let node = DeviceCategoryTree.addElementToTree(root, new DeviceCategoryTreeValue(element.categoryId, element.name));
        this.convertToTree(children, node, depth);
      } else {
        if (!element.isEmptyCell && !element.isGroup) {
          DeviceCategoryTree.addElementToTree(root, new DeviceCategoryTreeValue(element.categoryId, element.name, element.tag));
        }
      }
    }
  }

  setGroupName(index: number, groupName: string) {
    if (groupName) {
      this.targetBuilderTools[index].name = groupName;
    } else {
      this.translate.get('group').subscribe((res: string) => this.targetBuilderTools[index].name = res);
    }
    this.sendCategoryTree();
    this.setEditMode(this.targetBuilderTools[index]);
  }

  getGroupName(item: DragAndDropListElement) {
    this.setEditMode(item);
  }

  setEditMode(item: DragAndDropListElement) {
    item.isEditMode = !item.isEditMode;
  }

  castling(array: DragAndDropListElement[], startIndex: number, endIndex: number) {
    for (var i = startIndex; i < endIndex; i++) {
      if (array[i].isGroup) {
        this.castling(array, i + 1, i + array[i].scopeSize);
        i = i + array[i].scopeSize;
      } else {
        var itemToMove = array[i];
        array.splice(i, 1);
        array.splice(startIndex, 0, itemToMove);
      }
    }
  }

  sortRegularItem(array: DragAndDropListElement[], startIndex: number, endIndex: number): DragAndDropListElement[] {
    var quantityNotSorted: number = 0;
    for (var i = startIndex; i <= endIndex; i++) {
      var item = array[i];
      if (item.isGroup) {
        var elementsBeforeSortingPart = this.cloneArray(array, 0, startIndex);
        var elementsAfterSortingPart = this.cloneArray(array, i, array.length);
        var children = this.cloneArray(array, startIndex, i);
        var sortedChildren = children.sort((a, b) => {
          return a.tag.localeCompare(b.tag);
        }
        );
        array = elementsBeforeSortingPart.concat(sortedChildren).concat(elementsAfterSortingPart);
        array = this.sortRegularItem(array, i + 1, i + item.scopeSize);
        i = i + item.scopeSize;
        startIndex = i + item.scopeSize;
        quantityNotSorted = 0;
      } else {
        quantityNotSorted++;
      }

      if (item.isEmptyCell && quantityNotSorted > 1) {
        var elementsBeforeSortingPart = this.cloneArray(array, 0, startIndex);
        var elementsAfterSortingPart = this.cloneArray(array, i, array.length);
        var children = this.cloneArray(array, i - quantityNotSorted + 1, i);
        var sortedChildren = children.sort((a, b) => {
          return a.tag.localeCompare(b.tag);
        }
        );
        array = elementsBeforeSortingPart.concat(sortedChildren).concat(elementsAfterSortingPart);
        quantityNotSorted = 0;
      }
    }
    return array;
  }

  cloneArray(array: DragAndDropListElement[], startIndex: number, endIndex: number) {
    var clone: DragAndDropListElement[] = [];
    for (var i = startIndex; i < endIndex; i++) {
      clone.push(array[i]);
    }
    return clone;
  }

  sortCategories(targetList: DragAndDropListElement[]) {
    this.castling(targetList, 0, targetList.length);
    this.targetBuilderTools = this.sortRegularItem(targetList, 0, targetList.length - 1);
  }

  moveAllCategories() {
    for (let i = 1; i < this.sourceBuilderTools.length; i++) {
      this.targetBuilderTools.push(this.sourceBuilderTools[i])
    }
    this.sendCategoryTree();
  }

  onDeleteConfirm(confirmed: boolean) {
    if (confirmed) {
      this.deleteAll();
    }
  }

  deleteAll() {
    this.targetBuilderTools = [];
    this.sendCategoryTree();
  }

  convertToDnd(root: Node<DeviceCategoryTreeValue>, depth: number = 0): DragAndDropListElement[] {
    var sourceList: DragAndDropListElement[] = [];
    root.nodes.forEach(element => {
      var groupScope: DragAndDropListElement[] = [];
      if (element.value.id == -1) {
        var itemGroup = DragAndDropListElement.CreateGroup(element.value.fullName, depth);
        var subGroup: DragAndDropListElement[] = this.convertToDnd(element, itemGroup.depth + 1);
        groupScope = groupScope.concat(subGroup);

        var itemEmpty = DragAndDropListElement.CreateEmptyItem(itemGroup.depth + 1);
        groupScope.push(itemEmpty);
        itemGroup.scopeSize = groupScope.length;
        groupScope.unshift(itemGroup);
      } else {
        var category = this.categories.filter(x => x.id == element.value.id)[0];
        var item = DragAndDropListElement.CreateElementForSourceList(category, depth);
        groupScope.push(item);
      }
      sourceList = sourceList.concat(groupScope);
    });
    return sourceList;
  }

  updateBorders(index: number) {
    for (var i = index - 1; i > 0; i--) {
      var item = this.targetBuilderTools[i];
      var itemZoneFutureEnd = i + item.scopeSize + 1;
      if (item.isGroup && itemZoneFutureEnd > index) {
        item.scopeSize++;
      }
    }
  }
  updateBorders_while_inserting_element(event: CdkDragDrop<DragAndDropListElement[]>, quantityAddedElements: number): CdkDragDrop<DragAndDropListElement[]> {
    var index: number = event.currentIndex;
    for (var i = index - 1; i >= 0; i--) {
      var item = event.container.data[i];
      var itemZoneFutureEnd = i + item.scopeSize + 1;
      if (item.isGroup && itemZoneFutureEnd > index) {
        event.container.data[i].scopeSize += quantityAddedElements;
      }
    }
    return event;
  }
  updateBorders_while_removing_element(event: CdkDragDrop<DragAndDropListElement[]>, quantityRemovedElements: number): CdkDragDrop<DragAndDropListElement[]> {
    var index: number = event.previousIndex;
    for (var i = index - 1; i >= 0; i--) {
      var item = event.previousContainer.data[i];
      var itemZoneFutureEnd = i + item.scopeSize + 1;
      if (item.isGroup && itemZoneFutureEnd > index) {
        event.previousContainer.data[i].scopeSize -= quantityRemovedElements;
      }
    }
    return event;
  }
  updateBorders_while_inserting_elementIntoTargetBuilderTools(event: CdkDragDrop<DragAndDropListElement[]>, quantityAddedElements: number, containerCloneArray: DragAndDropListElement[]): CdkDragDrop<DragAndDropListElement[]> {
    var index: number = event.currentIndex;
    for (var i = index - 1; i >= 0; i--) {
      var item = event.container.data[i];
      var itemZoneFutureEnd = i + item.scopeSize + 1;
      var previousElement = containerCloneArray[event.previousIndex];
      if (item.isGroup && itemZoneFutureEnd >= index && item.id != previousElement.id) {
        var newScopeSize = this.targetBuilderTools[i].scopeSize + quantityAddedElements;
        this.targetBuilderTools[i].scopeSize = newScopeSize;
      }
    }
    return event;
  }
  updateBorders_while_removing_elementIntoTargetBuilderTools(event: CdkDragDrop<DragAndDropListElement[]>, quantityRemovedElements: number): CdkDragDrop<DragAndDropListElement[]> {
    var index: number = event.previousIndex;
    for (var i = index - 1; i >= 0; i--) {
      var item = event.previousContainer.data[i];
      var itemZoneFutureEnd = i + item.scopeSize + 1;
      if (item.isGroup && itemZoneFutureEnd > index) {
        this.targetBuilderTools[i].scopeSize -= quantityRemovedElements;
      }
    }
    return event;
  }
  ListTypeEnum = ListType;
  drop(event: CdkDragDrop<DragAndDropListElement[]>) {
    if (event.container.id === ListType.Template) {
      return;
    }
    if (event.previousContainer.id === event.container.id) {
      if (event.currentIndex === event.previousIndex && event.isPointerOverContainer) {
        return;
      }
      var elementsToMove: DragAndDropListElement[] = [];
      var parentElement = event.previousContainer.data[event.previousIndex];
      var groupSizeEnd = event.previousIndex + parentElement.scopeSize;
      if (parentElement.isGroup && event.currentIndex >= event.previousIndex && event.currentIndex <= groupSizeEnd) { // hidding for nesting themselves
        return;
      }
      var quantityTransferingElements = parentElement.scopeSize === 0 ? 1 : 1 + parentElement.scopeSize;
      event = this.updateBorders_while_removing_elementIntoTargetBuilderTools(event, quantityTransferingElements);
      event = this.defineDepthsBeforeMoveElements(event);
      if (!event.isPointerOverContainer) {
        for (var i = event.previousIndex; i <= groupSizeEnd; i++) {
          event.previousContainer.data.splice(event.previousIndex, 1);
        }
      } else {
        var containerCloneArray: DragAndDropListElement[] = [];
        event.container.data.forEach(val => containerCloneArray.push(val.clone()));
        this.moveItems(event);
        event = this.updateBorders_while_inserting_elementIntoTargetBuilderTools(event, quantityTransferingElements, containerCloneArray);
      }
    } else {
      var itemToMove = event.previousContainer.data[event.previousIndex].clone();
      var elementsToMove: DragAndDropListElement[] = [];
      elementsToMove.push(itemToMove);
      if (itemToMove.isGroup) {
        var emptyItem: DragAndDropListElement = DragAndDropListElement.CreateEmptyItem();
        elementsToMove.push(emptyItem);
      }
      elementsToMove = this.defineDepths(event, elementsToMove);
      event = this.updateBorders_while_inserting_element(event, elementsToMove.length);
      for (var i = elementsToMove.length - 1; i >= 0; i--) {
        copyArrayItem(
          elementsToMove,
          event.container.data,
          i,
          event.currentIndex
        );
      }
    }
    this.sendCategoryTree();
  }
  moveItems(event: CdkDragDrop<DragAndDropListElement[]>) {
    var parentElement = event.previousContainer.data[event.previousIndex]
    var endBorder = event.previousIndex + parentElement.scopeSize;
    if (event.previousIndex < event.currentIndex) {
      for (var i = event.previousIndex; i <= endBorder; i++) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      }
    } else {
      for (var i = endBorder; i >= event.previousIndex; i--) {
        moveItemInArray(event.container.data, endBorder, event.currentIndex);
      }
    }
  }

  defineDepths(event: CdkDragDrop<DragAndDropListElement[]>, elementsToMove: DragAndDropListElement[]): DragAndDropListElement[] {
    for (var i = 0; i < event.container.data.length; i++) {
      var item = event.container.data[i];
      if (item.isGroup) {
        var startBorder = i;
        var endBorder = item.scopeSize + i;
        if (startBorder < event.currentIndex && event.currentIndex <= endBorder) {
          elementsToMove.forEach(e => {
            if (e.isGroup) {
              e.depth = item.depth + 1;
            } else if (!e.isGroup && !e.isEmptyCell) {
              e.depth = item.depth + 1;
            } else if (e.isEmptyCell) {
              e.depth = item.depth + 2;
            }
          });
        }
      }
    }
    return elementsToMove;
  }
  defineDepthsMovedElements(event: CdkDragDrop<DragAndDropListElement[]>): CdkDragDrop<DragAndDropListElement[]> {
    var containerCloneArray: DragAndDropListElement[] = [];
    event.container.data.forEach(val => containerCloneArray.push(val.clone()));
    var isDepthChanged = false;
    for (var i = 0; i < containerCloneArray.length; i++) {
      var item = containerCloneArray[i];
      var parentElement = event.previousContainer.data[event.previousIndex];
      if (item.isGroup && item.id != parentElement.id) {
        var startBorder = i;
        var endBorder = item.scopeSize + i;
        if (startBorder <= event.currentIndex && event.currentIndex <= endBorder) {
          var endBorder = event.previousIndex + parentElement.scopeSize;
          for (var j = event.previousIndex; j < endBorder; j++) {
            var previousItem = event.previousContainer.data[j];
            if (previousItem.isGroup) {
              previousItem.depth = item.depth + 1;
              isDepthChanged = true;
            } else if (!previousItem.isGroup && !previousItem.isEmptyCell) {
              previousItem.depth = item.depth + 1;
              isDepthChanged = true;
            } else if (previousItem.isEmptyCell) {
              previousItem.depth = item.depth + 2;
              isDepthChanged = true;
            }
          }
        }
      }
    }
    return event;
  }
  defineDepthsBeforeMoveElements(event: CdkDragDrop<DragAndDropListElement[]>): CdkDragDrop<DragAndDropListElement[]> {
    var isDepthChanged = false;
    var containerCloneArray: DragAndDropListElement[] = [];
    event.container.data.forEach(val => containerCloneArray.push(val.clone()));
    for (var i = 0; i < containerCloneArray.length; i++) {
      var item = containerCloneArray[i];
      var parentElement = event.previousContainer.data[event.previousIndex];
      if (item.isGroup && item.id != parentElement.id) {
        var startBorder = i;
        var endBorder = item.scopeSize + i;
        if (startBorder <= event.currentIndex && event.currentIndex <= endBorder) {
          var endBorder = event.previousIndex + parentElement.scopeSize;
          for (var j = event.previousIndex; j <= endBorder; j++) {
            var previousItem = event.previousContainer.data[j];
            if (previousItem.isGroup) {
              previousItem.depth = item.depth + 1;
              isDepthChanged = true;
            } else if (!previousItem.isGroup && !previousItem.isEmptyCell) {
              previousItem.depth = item.depth + 1;
              isDepthChanged = true;
            } else if (previousItem.isEmptyCell) {
              previousItem.depth = item.depth + 2;
              isDepthChanged = true;
            }
          }
        }
      }
    }
    if (!isDepthChanged || event.currentIndex === 0 || event.currentIndex + 1 === event.container.data.length) {
      var parentElement = event.previousContainer.data[event.previousIndex];
      var endBorder = event.previousIndex + parentElement.scopeSize;
      for (var j = event.previousIndex; j <= endBorder; j++) {
        var previousItem = event.previousContainer.data[j];
        if (previousItem.isGroup) {
          previousItem.depth = 0;
          isDepthChanged = true;
        } else if (!previousItem.isGroup && !previousItem.isEmptyCell) {
          previousItem.depth = 0;
          isDepthChanged = true;
        } else if (previousItem.isEmptyCell) {
          previousItem.depth = 1;
          isDepthChanged = true;
        }
      }
    }
    return event;
  }
  dragStart(event: CdkDragStart<DragAndDropListElement[]>, item: DragAndDropListElement) {
    var dropContainerList = event.source.dropContainer.data;
    var previousIndex = -1;
    for (var i = 0; i < dropContainerList.length; i++) {
      if (dropContainerList[i].id === item.id) {
        previousIndex = i;
        break;
      }
    }
    for (var i = previousIndex; i < previousIndex + item.scopeSize + 1; i++) {
      event.source.dropContainer.data[i].isDragged = true;
    }
  }
  dragEnd(event: CdkDragEnd<DragAndDropListElement[]>, item: DragAndDropListElement) {
    var dropContainerList = event.source.dropContainer.data;
    var previousIndex = -1;
    for (var i = 0; i < dropContainerList.length; i++) {
      if (dropContainerList[i].id === item.id) {
        previousIndex = i;
        break;
      }
    }
    for (var i = previousIndex; i < previousIndex + item.scopeSize + 1; i++) {
      event.source.dropContainer.data[i].isDragged = false;
    }
  }
}