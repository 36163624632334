import { Pipe, PipeTransform } from '@angular/core';
import { DeviceType } from 'projects/qcloud-models/device/device-type.enum';

@Pipe({
  name: 'deviceType'
})
export class DeviceTypePipe implements PipeTransform {

  transform(value: number): string {
    switch (+value) {
      case DeviceType.CallConsole:
        return "call-console";
      case DeviceType.TicketDispenser:
        return "ticket-dispenser";
      case DeviceType.MainDisplay:
        return "main-display";
      case DeviceType.DeskDisplay:
        return "desk-display";
      // case DeviceType.OfficeConsole:
      //   return "Konsola gabinetowa";
      // case DeviceType.OfficeDisplay:
      //   return "Ekran gabinetowy";
      case DeviceType.CustomerSurveyDisplay:
        return "customer-survey-display";
      case DeviceType.CallingDisplay:
        return "calling-display";
        case DeviceType.TicketsForHoursDispenser:
        return "ticket-for-hours-dispenser";
      default:
        return "";
      }
  }

}
