import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Category } from 'projects/qcloud-models/category/category.model';
import { timeout } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { CategoryGroup } from 'projects/qcloud-models/category/category-group.model';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string){
    this.appBase = appBase;
  }

  getCategories(callback: (data: Category[]) => any, onError: () => any) {
    this.http.get(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/categories`)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }

  getCategory(categoryId: number) : Observable<Category> {
    return this.http.get<Category>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/categories/${categoryId}`);
  }

  addCategory(category: Category) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/categories`, category);
  }

  editCategory(category: Category) {
    return this.http.put(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/categories/${category.id}`,
      category);
  }

  deleteCategory(categoryId: number) {
    return this.http.delete(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/categories/${categoryId}`);
  }

  setAutomaticalReset(categories: Category[]) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/categories/restarts`, categories);
  }

  unblockCategories() {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/categories/unblockAll`,
      {});
  };

  blockCategory(categoryId: number) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/categories/${categoryId}/block`,
      {});
  };

  unblockCategory(categoryId: number) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/categories/${categoryId}/unblock`,
      {});
  };

  getCategoryGroups(): Observable<CategoryGroup[]> {
    return this.http.get<CategoryGroup[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/categories/groups`);
  };

  createCategoryGroups(categoryGroups: CategoryGroup[]) {
    return this.http.post(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/categories/groups`,
      categoryGroups);
  };

  getOnHourTimes(categoryId: number, callback: (data: string[]) => any, onError: (data: any) => any) {
    var getOnHourTimes = `api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/categories/${categoryId}/onHourTimes`;
    this.http.get(this.appBase + getOnHourTimes)
      .pipe(timeout(DEFAULT_TIMEOUT))
      .subscribe(
        callback,
        onError
      );
  }
}
