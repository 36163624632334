export enum LayoutType {
    Basic = 1,
    Multimedia = 2,
    Groups_View = 3,
    Vertical = 4,
    Horizontal = 5,
    MultideskCategory = 6,
    BackOffice = 7,
    Basic_mix = 8,
    MultideskDesk = 9,
    BasicLastTicketsRoom = 10,
    MultiTickets = 11,
    BasicLastTickets = 12,
    DesksBasic = 13,
    MultimediaFHD = 14,
    DeskDispenser = 15,
    MultiTicketsInQueue = 16,
    MultimediaTicketsInQueue = 17
}