import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenResponse } from '../../../qcloud-models/user/token-response';
import { Login } from 'projects/qcloud-models/user/login.model';
import { AuthService } from './auth/auth.service';


@Injectable()
export class QCloudRestClientService {

  private appBase: string;
  loginForm: Login;

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  public getAuthTokenForDevice(guid: string, callback: (data: TokenResponse) => any, onError: () => any) {
    var tokenUrl = `token`;
    let context = this;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
    };

    var callbackWrapper = function (data: TokenResponse) {
      context.authService.setToken(data.access_token);
      callback(data);
    };

    this.http.post(this.appBase + tokenUrl, `grant_type=password&deviceId=${guid}&client_id=qcloudWebApp&remember=false`,
      httpOptions)
      .subscribe(
        callbackWrapper,
        onError
      );
  }

  getAuthTokenForUser(callback: (data: TokenResponse) => any, onError: () => any) {
    let context = this;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
    };
    if (this.loginForm != undefined) {
      var username = this.loginForm.username;
      var password = this.loginForm.password;
    }
    var tokenUrl = `token`;
    var callbackWrapper = function (data: TokenResponse) {
      context.authService.setToken(data.access_token);
      context.authService.setServeETicket(data.canServeETickets);
      callback(data);
    };
    this.http.post(this.appBase + tokenUrl,
      `grant_type=password&username=${username}&password=${password}&client_id=qcloudWebApp&remember=false`,
      httpOptions
    )
      .subscribe(
        callbackWrapper,
        onError
      );
  }
}
