export class ActivityTime {
    public id: number;
    public categoryId: number;
    public day: number;
    public unblockingTime: any;
    public blockingTime: any;
    public isActive: boolean;
    public is24Hours: boolean;
    public isButtonAlwaysVisible: boolean;
}
