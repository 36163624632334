import { Component, OnInit } from '@angular/core';
import { TimeConversionService } from '../../../shared/time-conversion.service';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { ActivityTime } from 'projects/qcloud-models/activity-time';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Category } from 'projects/qcloud-models/category/category.model';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  category: Category;
  isEditForm: boolean;
  advancedMode: boolean = false;
  thumbLabel = true;
  formSubmitted: boolean = false;

  checkedTicketLimit = false;
  checkedTimePerTicket = false;
  checkedService = false;
  checkedPause = false;
  addPrintText = false;
  minService = 1;
  maxService = 120;
  minPause = 5;
  maxPause = 100;
  step = 5;
  nightWork: Array<boolean> = new Array(7);

  constructor(private categoryService: CategoryService, private timeConverter: TimeConversionService,
    private authService: AuthService, private router: Router, private route: ActivatedRoute,
    private translate: TranslateService, private toastr: ToastrService, public locationService: LocationService) { }

  ngOnInit(): void {
    let categoryId: number;
    this.route.params.subscribe(params => {
      categoryId = params['id']
    });
    if (categoryId) {
      this.isEditForm = true;
      this.getCategory(categoryId);
    } else {
      this.isEditForm = false;
      this.initializeCategory();
    }
  }

  initializeCategory() {
    this.category = new Category();
    this.category.locationId = +this.authService.getLocationId();
    this.category.isAutomaticallyReset = true;
    this.category.blockingMessageActivity = "Limit biletów został wyczerpany"; //TODO: get translations
    this.category.blockingMessageManual = "Limit biletów został wyczerpany";
    this.category.ticketAmountToCountAverageWaitingServiceTime = 100;
    this.category.unblockingTimeDaysValue = new Array<ActivityTime>(7);
    for (let i = 0; i < this.category.unblockingTimeDaysValue.length; i++) {
      this.category.unblockingTimeDaysValue[i] = new ActivityTime();
      this.category.unblockingTimeDaysValue[i].unblockingTime = "00:00";
      this.category.unblockingTimeDaysValue[i].blockingTime = "23:59";
      this.category.unblockingTimeDaysValue[i].is24Hours = false;
      this.category.unblockingTimeDaysValue[i].isActive = true;
      this.category.unblockingTimeDaysValue[i].isButtonAlwaysVisible = false;
      this.category.unblockingTimeDaysValue[i].day = i;
      this.nightWork[i] = false;
    }
  }

  getCategory(categoryId: number) {
    this.categoryService.getCategory(categoryId).subscribe(
      category => {
        this.category = category;
        if (this.category.ticketLimit != 0) {
          this.checkedTicketLimit = true;
        }
        if(this.checkedTicketLimit == false){
          this.category.ticketLimit = 0;
        }
        if (this.category.timePerTicket != 0) {
          this.checkedTimePerTicket = true;
        }
        if(this.checkedTimePerTicket == false){
          this.category.timePerTicket = 0;
        }
        if (this.category.maxServiceTime != 0) {
          this.checkedService = true;
        }
        if (this.category.defaultPauseTimeMins != 0) {
          this.checkedPause = true;
        }
        if (this.category.additionalPrint == true) {
          this.addPrintText = true;
        }
        for (let i = 0; i < this.category.unblockingTimeDaysValue.length; i++) {

          this.category.unblockingTimeDaysValue[i].blockingTime =
            this.timeConverter.dateToTime(this.category.unblockingTimeDaysValue[i].blockingTime);
          this.category.unblockingTimeDaysValue[i].unblockingTime =
            this.timeConverter.dateToTime(this.category.unblockingTimeDaysValue[i].unblockingTime);

          if(this.timeConverter.timeToMinutes(this.category.unblockingTimeDaysValue[i].blockingTime)
            < this.timeConverter.timeToMinutes(this.category.unblockingTimeDaysValue[i].unblockingTime)) {

              var tmp = this.category.unblockingTimeDaysValue[i].blockingTime;
              this.category.unblockingTimeDaysValue[i].blockingTime
                = this.category.unblockingTimeDaysValue[i].unblockingTime;
              this.category.unblockingTimeDaysValue[i].unblockingTime = tmp;
              this.nightWork[i] = true;
          }
        }
      }
    );
  }

  checkServiceTimeInput(serviceTime: number) {
    if (serviceTime < 1) {
      this.category.maxServiceTime = 1
    }
    if (serviceTime > 120) {
      this.category.maxServiceTime = 120
    }
  }

  checkPauseTimeInput(pauseTime: number) {
    if (pauseTime < 1) {
      this.category.defaultPauseTimeMins = 1
    }
    else{
      this.category.defaultPauseTimeMins = pauseTime
    }
  }

  onSubmit() {
    this.formSubmitted = true;
    for (let i = 0; i < this.category.unblockingTimeDaysValue.length; i++) {
      if (this.nightWork[i] == true) {
        var tmp = this.category.unblockingTimeDaysValue[i].unblockingTime;
        this.category.unblockingTimeDaysValue[i].unblockingTime
          = this.category.unblockingTimeDaysValue[i].blockingTime;
        this.category.unblockingTimeDaysValue[i].blockingTime = tmp;
      }
    }
    this.category.unblockingTimeDaysValue = this.changeTimeModel(this.category.unblockingTimeDaysValue);
    if (this.isEditForm) {
      this.editCategory();
    } else {
      this.addCategory();
    }
  }

  addCategory() {
    this.category.id = 0;
    this.category.unblockingTimeDaysValue.forEach(element =>
    {
      element.id = 0;
      element.categoryId = 0;
    });
    if(this.checkedTicketLimit == false){
      this.category.ticketLimit = 0;
    }
    if(this.checkedTimePerTicket == false){
      this.category.timePerTicket = 0;
    }
    this.categoryService.addCategory(this.category).subscribe(
      res => {
        this.router.navigate(['/categories']);
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('category-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  editCategory() {
    if(this.checkedTicketLimit == false){
      this.category.ticketLimit = 0;
    }
    if(this.checkedTimePerTicket == false){
      this.category.timePerTicket = 0;
    }
    this.categoryService.editCategory(this.category).subscribe(
      res => {
        this.router.navigate(['/categories']);
      },
      err => {
        this.formSubmitted = false;
        this.translate.get('category-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  changeTimeModel(time: Array<ActivityTime>) {
    time.forEach(element => {
      element.blockingTime = this.timeConverter.timeToDate(element.blockingTime);
      element.unblockingTime = this.timeConverter.timeToDate(element.unblockingTime);
    });
    return time;
  }

  newBasedOnExisting() {
    this.isEditForm = false;
  }

}
