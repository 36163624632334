import { Category } from "../category/category.model";

export class User {
    public id: string;
    public locationId: number;
    public systemId: number;
    public firstName: string;
    public lastName: string;
    public userName: string;
    public email: string;
    public categories: Category[];
    public role: string;
    public active: boolean;
    public hasAccessToConsole: boolean;
    public hasPermissionToCallingTicketsManually: boolean;
    public hasPermissionToBlockingCategoryManually: boolean;
    public manualCategoryCalling: boolean;
    public canGenerateTicketsInConsole: boolean;
    public hasPermissionToReserveTickets: boolean;
    public hasPermissionToConfigureGrafik: boolean;
    public hasPermissionToServeETickets: boolean;
}
