import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(this.authService.isLoggedIn()) {
      const cloneReq = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + this.authService.getToken())
      });
      return next.handle(cloneReq).pipe(
        tap(
          suc => {},
          err => {
            if(err.status == 401 || err.status == 403 || this.authService.isTokenExpired()) {
                this.authService.onLogout();
            }
          }
        )
      );
    }
    else {
      return next.handle(request.clone());
    }
  }
}
