import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Category } from 'projects/qcloud-models/category/category.model';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { ReportScope } from 'projects/qcloud-models/report/report-scope.enum';
import { QCloudElement, ReportTemplateParameter } from 'projects/qcloud-models/report/report-template-parameter';
import { User } from 'projects/qcloud-models/user/user.model';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';

@Component({
  selector: 'app-report-template-parameter',
  templateUrl: './report-template-parameter.component.html',
  styleUrls: ['./report-template-parameter.component.css']
})
export class ReportTemplateParameterComponent implements OnInit {

  @Input() reportTemplateParameter: ReportTemplateParameter;
  ReportScope = ReportScope;

  @Input() categories: Category[];  
  @Input() desks: Desk[];
  @Input() users: User[];

  categoryMultiCtrl: UntypedFormControl;
  servedCategories = 'served-categories';
  noSelectedCategories = 'no-selected-categories';
  deskMultiCtrl: UntypedFormControl;
  servedDesks = 'desks';
  noSelectedDesks = 'no-selected-desks';
  userMultiCtrl: UntypedFormControl;
  servedUsers = 'users';
  noSelectedUsers = 'no-selected-users';

  constructor(public locationService: LocationService) { }

  ngOnInit(): void {
    this.categoryMultiCtrl = new UntypedFormControl([]);
    this.deskMultiCtrl = new UntypedFormControl([]);
    this.userMultiCtrl = new UntypedFormControl([]);
    if (this.reportTemplateParameter.reportScope == ReportScope.Categories) {
      let initialCategories = this.categories.filter(x => this.reportTemplateParameter.elements.find(y => y.id == x.id));
      this.categoryMultiCtrl = new UntypedFormControl(initialCategories);
      this.selectCategories(initialCategories);
    }
    if (this.reportTemplateParameter.reportScope == ReportScope.Desks) {
      let initialDesks = this.desks.filter(x => this.reportTemplateParameter.elements.find(y => y.id == x.id));
      this.deskMultiCtrl = new UntypedFormControl(initialDesks);
      this.selectDesks(initialDesks);
    }
    if (this.reportTemplateParameter.reportScope == ReportScope.Users) {
      let initialUsers = this.users.filter(x => this.reportTemplateParameter.elements.find(y => y.id == x.id));
      this.userMultiCtrl = new UntypedFormControl(initialUsers);
      this.selectUsers(initialUsers);
    }
  }

  selectCategories(categories: Category[]) {
    this.reportTemplateParameter.elements = categories.map(category => new QCloudElement(category.id, category.fullName));
  }

  selectDesks(desks: Desk[]) {
    this.reportTemplateParameter.elements = desks.map(desk => new QCloudElement(desk.id, desk.fullName));
  }

  selectUsers(users: User[]) {
    this.reportTemplateParameter.elements = users.map(user => new QCloudElement(user.id, user.firstName + " " + user.lastName));
  }

}
