<div class="d-sm-flex align-items-center justify-content-between mb-4">
    <h1 class="h3 mb-0 text-gray-800">{{ 'dashboard' | translate }}</h1>
    <a routerLink="/statistics/report" class="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"><i
        class="fas fa-download fa-sm text-white-50"></i> {{ 'generate-report' | translate }}</a>
</div>

<div *ngIf="ticketStats && categories && desks && users">
  <div class="card-group">
      <div class="card shadow mb-4">
          <app-ticket-result-statistics
              [processedTicketsCount]="ticketStats.processedTicketsCount">
          </app-ticket-result-statistics>
      </div>
      <div class="card shadow mb-4 mx-4">
          <app-queue-state-statistics [queueState]="ticketStats.queueState" [categories]="categories">
          </app-queue-state-statistics>
      </div>
      <div class="card shadow mb-4">
          <app-average-waiting-service-time-statistics
              [averageWaitingTime]="ticketStats.averageWaitingTime" [averageServiceTime]="ticketStats.averageServiceTime">
          </app-average-waiting-service-time-statistics>
      </div>
  </div>

  <div class="row">
    <div class="col-lg-4 mb-4">
      <div class="card bg-primary text-white shadow">
        <div class="card-body">
          <h1>{{ categoryCount }}</h1>
          {{ 'categories' | translate }}
        </div>
      </div>
    </div>
    <div class="col-lg-4 mb-4">
      <div class="card bg-info text-white shadow">
        <div class="card-body">
          <h1>{{ deskCount }}</h1>
          {{ 'desks' | translate }}
        </div>
      </div>
    </div>
    <div class="col-lg-4 mb-4">
      <div class="card bg-success text-white shadow">
        <div class="card-body">
          <h1>{{ userCount }}</h1>
          {{ 'users' | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="card shadow mb-4">
    <app-daily-queueing-statistics [categories]="categories"
        [dailyCategoryQueue]="ticketStats.dailyCategoryQueue">
    </app-daily-queueing-statistics>
  </div>
</div>