<div class="card shadow mb-4">
    <div class="card-header py-3">
        <h5 class="col-10 m-0 font-weight-bold text-primary">{{ 'access-system-integration-title' | translate }}</h5>
    </div>
    <div class="card-body">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>#</th>
                    <th>{{ 'categories' | translate }}</th>
                    <th>{{ 'parent-id' | translate }}</th>
                    <th>{{ 'facility-code' | translate }}</th>
                    <th>{{ 'validity-length' | translate }}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of axxonData; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>
                        <mat-form-field class="ml-2">
                            <mat-select name="categoryId" #deskId="ngModel" [(ngModel)]="item.categoryId">
                                <mat-option *ngFor="let category of categories" [value]="category.id">{{
                                    category.fullName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td>
                        <div class="input-group">
                            <input [(ngModel)]="item.parentId" class="form-control">
                        </div>
                    </td>
                    <td>
                        <div class="input-group">
                            <input [(ngModel)]="item.facilityId" class="form-control">
                        </div>
                    </td>
                    <td>
                        <div class="input-group">
                            <input [(ngModel)]="item.validityLength" class="form-control">
                        </div>
                    </td>
                    <td>
                        <button class="btn btn-danger btn-circle btn-sm mx-1 mb-1" tooltipclass="tooltip-danger" (click)="remove(i)">
                            <i class="fas fa-fw fa-trash"></i>
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        <button class="btn btn-primary m-1" (click)="addNew()">{{ 'add' | translate }}</button>
        <button class="btn btn-primary m-1" (click)="saveCodes()">{{ 'save' | translate }}</button>
    </div>
</div>