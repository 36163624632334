import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LocationActivityBlock } from 'projects/qcloud-models/location/location-activity-block.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { LocationService } from 'projects/qcloud-rest-client/src/lib/location.service';

@Component({
  selector: 'app-category-activity-blocks',
  templateUrl: './category-activity-blocks.component.html',
  styleUrls: ['./category-activity-blocks.component.css']
})
export class CategoryActivityBlocksComponent implements OnInit {

  @Input() categoryId: number;
  locationActivityBlocks: LocationActivityBlock[];
  addingNewActivityBlock: boolean;
  newActivityBlock: LocationActivityBlock;
  changedActivityBlock: LocationActivityBlock;

  constructor(private locationService: LocationService, private authService: AuthService,
    private translate: TranslateService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getLocationActivityBlocks(this.categoryId);
    this.changedActivityBlock = 
      new LocationActivityBlock(+this.authService.getLocationId(), +this.authService.getSystemId());
  }

  getLocationActivityBlocks(categoryId: number) {
    this.locationService.getActivity().subscribe(
      res => {
        this.locationActivityBlocks = res.filter(x => x.blockedCategoryIds.find(y => y == categoryId));
        this.locationActivityBlocks.forEach(activityBlock => {
          activityBlock.dateStart = new Date(activityBlock.dateStart);
          activityBlock.dateEnd = new Date(activityBlock.dateEnd);
        });
      }
    );
  }

  editLocationActivityBlock(newActivityBlock: LocationActivityBlock, activityBlock: LocationActivityBlock) {
    if (activityBlock) {
      activityBlock.blockedCategoryIds = activityBlock.blockedCategoryIds.filter(x => x != this.categoryId);
    }
    if (newActivityBlock) {
      newActivityBlock.blockedCategoryIds = [this.categoryId];
      this.locationActivityBlocks.push(newActivityBlock);
    }
    this.locationService.setActivity(this.locationActivityBlocks).subscribe(
      res => {
        this.translate.get('location-activity-blocks-success').subscribe((res: string) => {
          this.toastr.success(res);
          this.getLocationActivityBlocks(this.categoryId);
        });
      },
      err => {
        this.translate.get('location-activity-blocks-error').subscribe((error: string) => {
          this.translate.get('repeat').subscribe((res: string) => {
            this.toastr.error(res, error);
          });
        });
      }
    );
  }

  editActivityBlock(activityBlock: LocationActivityBlock) {
    this.newActivityBlock = 
      new LocationActivityBlock(+this.authService.getLocationId(), +this.authService.getSystemId());
    if (activityBlock) {
      this.changedActivityBlock = activityBlock;
    } else {
      this.changedActivityBlock = this.newActivityBlock;
    }
  }

  onSubmitActivityBlock(activityBlock: LocationActivityBlock) {
    this.editLocationActivityBlock(this.newActivityBlock, activityBlock);
    this.editActivityBlock(this.newActivityBlock);
  }

  deleteActivityBlock(activityBlock: LocationActivityBlock) {
    this.editLocationActivityBlock(null, activityBlock);
    this.editActivityBlock(this.newActivityBlock);
  }

  getRange(range: Date[]) {
    this.newActivityBlock.dateStart = range[0];
    this.newActivityBlock.dateEnd = range[1]; 
  }

}
