import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router) { }

  getToken() {
    return localStorage.getItem('token');
  }

  getConsoleToken() {
    return sessionStorage.getItem('consoleToken');
  }

  getLocationId(): number {
    return Number(localStorage.getItem('locationId'));
  }

  getSystemId(): number {
    return Number(localStorage.getItem('systemId'));
  }

  getDeskId(): number {
    return Number(localStorage.getItem('deskId'));
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
    localStorage.setItem('locationId', this.getTokenLocationId());
    localStorage.setItem('systemId', this.getTokenSystemId());
  }

  setConsoleToken(token: string) {
    sessionStorage.setItem('consoleToken', token);
    this.setToken(token);
    localStorage.setItem('deskId', this.getConsoleTokenDeskId());
  }

  setLocationId(locationId: number) {
    localStorage.setItem('locationId', locationId.toString());
  }

  setSystemId(systemId: number) {
    localStorage.setItem('systemId', systemId.toString());
  }

  setDeskId(deskId: number) {
    localStorage.setItem('deskId', deskId.toString());
  }

  setServeETicket(serveEticket: boolean) {
    localStorage.setItem('eticket', serveEticket.toString());
  }

  getServeETicket() {
    return localStorage.getItem('eticket');
  }

  isLoggedIn(): boolean {
    return this.getToken() != null;
  }

  isConsoleLoggedIn(): boolean {
    return this.getConsoleToken() != null;
  }

  isTokenExpired() {
    let token = this.getDecodedTokenData(this.getToken());
    if (!token) {
      return true;
    }
    let date = new Date(0);
    date.setUTCSeconds(token.exp);
    if (date === undefined) {
      return false;
    }
    return (date.valueOf() < new Date().valueOf());
  }

  onLogout() {
    localStorage.removeItem('token');
    this.router.navigateByUrl('/login');
  }

  onConsoleLogout() {
    sessionStorage.removeItem('consoleToken');
  }

  getDecodedTokenData(token: string) {
    let jwtData = token.split('.')[1];
    let decodedTokenJsonData = window.atob(jwtData);
    return JSON.parse(decodedTokenJsonData);
  }

  getTokenUsername() {
    let decodedTokenData = this.getDecodedTokenData(this.getToken());
    return decodedTokenData.name;
  }

  getTokenUserId() {
    let decodedTokenData = this.getDecodedTokenData(this.getToken());
    return decodedTokenData.userId;
  }

  getTokenUserRole() {
    let decodedTokenData = this.getDecodedTokenData(this.getToken());
    return decodedTokenData['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
  }

  isManagerOrHigher(): boolean {
    let userRole = this.getTokenUserRole();
    return userRole == "Manager" || userRole == "Administrator" || userRole == "SuperAdministrator";
  }

  isAdminOrHigher(): boolean {
    let userRole = this.getTokenUserRole();
    return userRole == "Administrator" || userRole == "SuperAdministrator";
  }

  isSuperAdmin(): boolean {
    return this.getTokenUserRole() == "SuperAdministrator";
  }

  roleMatch(allowedRoles: string[]): boolean {
    return allowedRoles.includes(this.getTokenUserRole());
  }

  getTokenLocationId() {
    let decodedTokenData = this.getDecodedTokenData(this.getToken());
    return decodedTokenData.locationId;
  }

  getTokenSystemId() {
    let decodedTokenData = this.getDecodedTokenData(this.getToken());
    return decodedTokenData.systemId;
  }

  getConsoleTokenDeskId() {
    let decodedTokenData = this.getDecodedTokenData(this.getConsoleToken());
    return decodedTokenData.deskId;
  }

  getConsoleTokenUserId() {
    let decodedTokenData = this.getDecodedTokenData(this.getConsoleToken());
    return decodedTokenData.userId;
  }
}
