import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth/auth.service';
import { Observable } from 'rxjs';
import { DeviceLayout } from 'projects/qcloud-models/device-layout';

const DEFAULT_TIMEOUT = 3000;

@Injectable({
  providedIn: 'root'
})
export class DeviceLayoutService {

  appBase: string;

  constructor(private http: HttpClient, private authService: AuthService) { }

  public setAppBase(appBase: string) {
    this.appBase = appBase;
  }

  getDeviceLayouts(): Observable<DeviceLayout[]> {
    return this.http.get<DeviceLayout[]>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/deviceLayouts`);
  }

  saveDeviceLayouts(layouts: DeviceLayout[]) {
    return this.http.post<DeviceLayout>(
      `${this.appBase}api/${this.authService.getSystemId()}/${this.authService.getLocationId()}/deviceLayouts`, layouts);
  }
}
