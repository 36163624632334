export enum MainDisplayLayouts {
    Basic = 1,
    Multimedia = 2,
    GroupView = 3,
    MultideskCategory = 6,
    BackOffice = 7,
    BasicMix = 8,
    MultideskDesk = 9,
    BasicLastTicketsRoom = 10,
    MultiTickets = 11,
    BasicLastTickets = 12,
    DeskBasic = 13,
    MultimediaFHD = 14,
    MultiTicketsInQueue = 16,
    MultimediaTicketsInQueue = 17
}