import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { Category } from 'projects/qcloud-models/category/category.model';
import { Desk } from 'projects/qcloud-models/desk/desk';
import { TicketsStatistics } from 'projects/qcloud-models/statistics/ticket-statistics';
import { User } from 'projects/qcloud-models/user/user.model';
import { CategoryService } from 'projects/qcloud-rest-client/src/lib/category.service';
import { DeskService } from 'projects/qcloud-rest-client/src/lib/desk.service';
import { StatisticsService } from 'projects/qcloud-rest-client/src/lib/statistics.service';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  categories: Category[];
  categoryCount: number;
  desks: Desk[];
  deskCount: number;
  users: User[];
  userCount: number;
  ticketStats: TicketsStatistics;

  constructor(private categoryService: CategoryService, private deskService: DeskService,
    private userService: UserService, private statisticsServcie: StatisticsService) { }

  ngOnInit(): void {
    this.statisticsServcie.getTodayStats().subscribe(
      res => {
        this.ticketStats = res;
      }
    );

    this.categoryService.getCategories(
      (data)=>{
        this.categories = data;
        this.categoryCount = this.categories.length;
      },
      ()=>{ }
    );

    this.deskService.getDesks(
      (data)=>{
        this.desks = data;
        this.deskCount = this.desks.length;
      },
      ()=>{ }
    );

    this.userService.getUsers(
      (data)=>{
        this.users = data;
        this.userCount = this.users.length;
      },
      ()=>{ }
    );

    //Plugin for center text
    Chart.pluginService.register({
      beforeDraw: function(chart) {
        if (chart.config.options.elements.center) {
          
          let ctx = chart.chart.ctx;
    
          let centerConfig = chart.config.options.elements.center;
          let fontStyle = centerConfig.fontStyle || 'sans-serif';
          let txt = centerConfig.text;
          let color = centerConfig.color || '#000';
    
          let height = chart.chart.height;
          let fontSize = (height / 100).toFixed(2);
          ctx.font =  fontSize + "em " + fontStyle;
          ctx.fillStyle = color;

          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          let centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
          let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
              
          ctx.fillText(txt, centerX, centerY);
        }
      }
    });
  }

}
