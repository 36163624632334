import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Category } from 'projects/qcloud-models/category/category.model';
import { take, takeUntil } from 'rxjs/operators';
import { Subject, ReplaySubject } from 'rxjs';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-served-categories',
  templateUrl: './served-categories.component.html',
  styleUrls: ['./served-categories.component.css']
})
export class ServedCategoriesComponent implements OnInit {

  @Input() categories: Category[];
  @Input() categoryMultiCtrl: UntypedFormControl;
  public categoryMultiFilterCtrl: UntypedFormControl = new UntypedFormControl();
  public filteredCategoriesMulti: ReplaySubject<Category[]> = new ReplaySubject<Category[]>(1);

  protected _onDestroy = new Subject<void>();

  @Input() title: string;
  @Input() noSelectedCategories: string;
  @Input() displayCategories: boolean;
  @Output() categoriesToEmit = new EventEmitter<Category[]>();

  constructor() { }

  ngOnInit(): void {
    // load the initial category list
    this.filteredCategoriesMulti.next(this.categories.slice());
    // listen for search field value changes
    this.categoryMultiFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterCategoriesMulti();
    });
  }

  emitCategories(categories: Category[]) {
    this.categoriesToEmit.emit(categories);
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  toggleSelectAll(selectAllValue: boolean) {
    this.filteredCategoriesMulti.pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(val => {
        if (selectAllValue) {
          this.categoryMultiCtrl.patchValue(val);
        } else {
          this.categoryMultiCtrl.patchValue([]);
        }
        this.categoriesToEmit.emit(this.categoryMultiCtrl.value);
      });
  }

  protected filterCategoriesMulti() {
    if (!this.categories) {
      return;
    }
    // get the search keyword
    let search = this.categoryMultiFilterCtrl.value;
    if (!search) {
      this.filteredCategoriesMulti.next(this.categories.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Categories
    this.filteredCategoriesMulti.next(
      this.categories.filter(category => category.fullName.toLowerCase().indexOf(search) > -1)
    );
  }

}
