import { Component, OnInit } from '@angular/core';
import { Ticket } from 'projects/qcloud-models/console/ticket';
import { TicketNoteVm } from 'projects/qcloud-models/console/TicketNoteVm';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-note-ticket-modal',
  templateUrl: './note-ticket-modal.component.html',
  styleUrls: ['./note-ticket-modal.component.css']
})
export class NoteTicketModalComponent implements OnInit {


  TicketNoteVm: TicketNoteVm;
  isConfirmVisible: Boolean;
  ticket: Ticket;
  
  constructor(private consoleService: QcloudConsoleService) {
    this.ticket = new Ticket();
    this.consoleService.getLastTicket();
    this.consoleService.ticket.subscribe((val: Ticket) => {
       this.ticket.id = val.id;
      });
    this.TicketNoteVm=new TicketNoteVm();
    this.TicketNoteVm.Note="";
  }
  
  ngOnInit(): void {
    this.isConfirmVisible = false;
  }

  addNote(ticketId: number) {
    this.consoleService.addNote(ticketId,this.TicketNoteVm);
    this.toggleConfirm();
    this.consoleService.setIsLockedStatus(false);
  }

  toggleConfirm(){
    this.isConfirmVisible = !this.isConfirmVisible;
  }

}
