import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocationConstraints } from 'projects/qcloud-models/location/location-constraints';
import { User } from 'projects/qcloud-models/user/user.model';
import { AuthService } from 'projects/qcloud-rest-client/src/lib/auth/auth.service';
import { LicenseService } from 'projects/qcloud-rest-client/src/lib/license.service';
import { UserService } from 'projects/qcloud-rest-client/src/lib/user.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit {

  user: User;
  locationConstraints: LocationConstraints;

  constructor(private router: Router, public authService: AuthService, private userService: UserService,
    private licenseService: LicenseService) { }

  ngOnInit(): void {
    this.userService.getUser(this.authService.getTokenUserId()).subscribe(
      res => {
        this.user = res;
        this.licenseService.getLocationsConstraints().subscribe(
          res => {
            this.locationConstraints = res;
            let permissions = [];
            if (this.isConsoleVisible()) {
              permissions.push('qcloud/web-console');
            }
            if (this.authService.isManagerOrHigher()) {
              permissions.push('');
            }
            if (this.isReservationVisible()) {
              permissions.push('qcloud/reservations');
            }
            if (this.isWorkScheduleVisible()) {
              permissions.push('qcloud/workSchedule');
            }
            if (permissions.length == 1) {
              this.router.navigate(permissions);
            }
          }
        );
      }
    );
  }

  goToDashboard() {
    this.router.navigate(['']);
  }

  goToConsole() {
    this.router.navigate(['qcloud/web-console']);
  }

  goToReservation() {
    this.router.navigate(['qcloud/reservations']);
  }

  goToWorkSchedule() {
    this.router.navigate(['qcloud/workSchedule']);
  }

  isConsoleVisible(): boolean {
    return this.user.hasAccessToConsole;
  }

  isReservationVisible(): boolean {
    return this.user.hasPermissionToReserveTickets && this.locationConstraints.areEnabledTicketsReservations;
  }

  isWorkScheduleVisible(): boolean {
    return this.user.hasPermissionToConfigureGrafik && this.locationConstraints.isWorkersScheduleEnabled;
  }
}
