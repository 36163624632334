import { Component, OnInit } from '@angular/core';
import { QcloudConsoleService } from '../../qcloud-console.service';

@Component({
  selector: 'lib-redirect-modal',
  templateUrl: './redirect-modal.component.html',
  styleUrls: ['./redirect-modal.component.css']
})
export class RedirectModalComponent implements OnInit {

  constructor(private consoleService: QcloudConsoleService) { }

  ngOnInit(): void {
  }

  public redirectToCategory() {
    this.consoleService.openRedirectToCategoryModal();
  }
  public redirectToDesk() {
    this.consoleService.openRedirectToDeskModal();
  }
  public redirectToForHour() {
    this.consoleService.redirectToCategoryTypeForHourModal();
  }

}
