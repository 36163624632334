import { User } from "../user/user.model";

export class TicketHistoryLog {
    public ticketId: number;
    public categoryId: number;
    public printedCategoryId: number;
    public oldState: number;
    public newState: number;
    public deskId: number;
    public redirectedDeskId: number;
    public eventTime: string;
    public eventType: string;
    public ticketNumber: string;
    public calledById: number;
    public info: string;
    public note: string;
    public user: User;

    constructor(data: any){
        this.ticketId = data.ticketId;
        this.categoryId = data.categoryId;
        this.printedCategoryId = data.printedCategoryId;
        this.oldState = data.oldState;
        this.newState = data.newState;
        this.deskId = data.deskId;
        this.redirectedDeskId = data.redirectedDeskId;
        this.eventTime = this.getLocalDateTime(data.timeStamp);
        this.eventType = "";
        this.ticketNumber = this.generateFullNumber(data.ticketNumber.toString());
        this.calledById = data.calledById;      
        this.info = "";
        this.note = data.note;
        this.user = new User();
    }

    generateFullNumber(number){
        if(number.length==1)return "00" + number;
        if(number.length==2)return '0' + number;
        return number;
    }

    getLocalDateTime(dateTime){
        let zoneOffset = new Date().getTimezoneOffset();
        let localDateTime = new Date(dateTime)
        localDateTime.setMinutes(localDateTime.getMinutes() - zoneOffset);
        return localDateTime.toLocaleString();
    }
}